<template>
  <div class="container" v-if="loading == false">
    <h1 class="title">Résumé de votre questionnaire</h1>
    <t-alert class="text-left" :dismissible="false" show
      >Merci de vérifier les réponses du questionnaire avant validation.<br />
    </t-alert>
    <div class="sticky top-0 z-50 mt-3">
      <button
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4"
        @click="sendResponse"
      >
        Valider mes réponses
      </button>
    </div>
    <div class="question__card" v-for="(q, index) in questions" :key="q.index">
      <div class="question">
        <h1 class="q">{{ index + 1 }}) {{ q.title }}</h1>
      </div>
      <div class="answer">
        <div
          v-if="
            q.choices.length == 0 &&
            (q.response_type == 'SHORT_ANSWER' ||
              q.response_type == 'TEXT' ||
              q.response_type == 'DATE')
          "
        >
          {{ q.user_response[0].value }}
          <font-awesome-icon
            @click="edit(q.index)"
            icon="fa-solid fa-pen-to-square"
          />
        </div>
        <div v-else-if="q.response_type == 'RANGE'">
          Entre {{ q.user_response[0].min_value }} et
          {{ q.user_response[0].max_value }}
          <font-awesome-icon
            @click="edit(q.index)"
            icon="fa-solid fa-pen-to-square"
          />
        </div>
        <div v-else-if="q.response_type == 'REGION'">
          {{
            regions.filter((r) => r.value == q.user_response[0].value)[0].name
          }}
          <font-awesome-icon
            @click="edit(q.index)"
            icon="fa-solid fa-pen-to-square"
          />
        </div>

        <div v-else>
          <Tag
            v-for="(a, index) in q.user_response"
            :key="index"
            class="mx-2"
            :value="
              q.choices.filter(
                (c) => a.response != null && c.id == a.response.id
              )[0].title
            "
          >
          </Tag>
          &nbsp;&nbsp;
          <font-awesome-icon
            icon="fa-solid fa-pen-to-square"
            @click="edit(q.index)"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <ProgressStep />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ProgressStep from '../components/ProgressStep.vue'
import Tag from 'primevue/tag'
import * as Sentry from '@sentry/vue'

export default {
  name: 'SurveySummarize',
  props: {},
  components: {
    ProgressStep,
    Tag,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      surveyItem: 'getSurveyItem',
      regions: 'regions',
      auth: 'auth',
    }),
    questions() {
      return this.surveyItem.filter(
        (i) => i.type == 'QUESTION' && i.user_response.length > 0
      )
    },
  },
  methods: {
    sendResponse() {
      this.loading = true
      setTimeout(async () => {
        try {
          await this.$store.dispatch('sendUserResponse')
          await this.$router.push({
            name: 'Meeting',
          })
        } catch (error) {
          Sentry.captureException(error)
        }
      }, 9000)
    },
    edit(index) {
      console.log('edit' + ' ' + index)
      this.$store.dispatch('editResponse', index)
    },
  },
}
</script>

<style scoped>
.container {
  width: 100%;
  max-width: 800px;
  margin: auto;
  padding: 10px;
}
.question {
  padding: 15px;
}
.answer {
  padding: 20px;
  text-align: left;
  font-size: 1.2rem;
}
.question__card {
  margin-top: 10px;
  margin-bottom: 30px;
  width: 100%;
  background: #fff;
  height: 150px;
  border-radius: 5px;
  border-left: 5px solid #cc1db9;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.title {
  font-size: 3rem;
  font-family: 'Serif';
}
.q {
  font-size: 1.3rem;
  text-align: left;
  font-family: 'Serif';
}
</style>
