var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h1',{staticClass:"title text-center"},[_vm._v("Je découvre mes matchs")]),(_vm.matchs.p1 != null)?_c('div',{staticClass:"w-full flex-1 p-10 text-center mr-3 mb-3 match__card_1",staticStyle:{"border-radius":"20px"}},[_c('div',{staticClass:"mb-3"},[_c('Avatar',{staticStyle:{"background-color":"#5e8f32","color":"#ffffff"},attrs:{"label":_vm.matchs.p1.match_id.first_name.substring(0, 1).toUpperCase(),"shape":"circle","size":"large"}})],1),_c('div',{staticClass:"my-3"},[_c('div',{staticClass:"mb-2 font-bold"},[_vm._v(" "+_vm._s(_vm.matchs.p1.match_id.first_name)+", "+_vm._s(_vm.getAge(_vm.matchs.p1.match_id.profil_attribute))+" ans,"+_vm._s(_vm.getHeight(_vm.matchs.p1.match_id.profil_attribute))+" cm, Paris ")]),_c('div',{staticClass:"mb-3"},[_c('Badge',{attrs:{"value":_vm.avg(
              _vm.percent(_vm.matchs.p1.total_criterias / 21).toFixed(0),
              _vm.percent(_vm.matchs.p2.total_criterias / 21).toFixed(0)
            ) + '%',"size":"small","severity":_vm.getSeverity(
              _vm.avg(
                _vm.percent(_vm.matchs.p1.total_criterias / 21).toFixed(0),
                _vm.percent(_vm.matchs.p2.total_criterias / 21).toFixed(0)
              )
            )}})],1),_c('div',{staticClass:"mt-5"},[_c('router-link',{staticClass:"mx-auto p-button-rounded p-button text-center",staticStyle:{"padding":"10px 50px"},attrs:{"to":{
            name: 'UserDetail',
            params: { id: _vm.matchs.p1.match_id.id },
          },"label":"Démarrer","icon":"pi pi-arrow-right","iconPos":"right"}},[_vm._v("Voir le profil")])],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }