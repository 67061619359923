<template>
    <div style="width: 100%;max-width: 500px;margin: auto;">
        <div style="margin-top: 5px;">
        <div class="text-center">
            <small>Veuillez suivre ces instructions pour votre photo de profil : Assurez une bonne luminosité sans ombres sur votre visage. <strong>Votre visage doit être bien dégagé</strong>, sans accessoires le cachant. <strong>Prenez une photo de face</strong>, en regardant directement la caméra. <strong>Portez une tenue appropriée et professionnelle</strong>. Enfin, assurez-vous d'être seul(e) sur la photo, sans autres personnes visibles.</small>
        </div>
        <div class="flex" style="gap: 10px;margin-top: 10px;">
        <div v-if="!hasUploaded" class="flex" style="cursor: pointer;">
            <input id="file-upload-avatar" type="file" value="charger"  @change="uploadAvatar" ref="fileUploadInput"/>
            <label for="file-upload-avatar" id="upload" style="background-color: #2196F3;color: #fff;font-weight: 600;cursor: pointer;"> 
                <font-awesome-icon
                icon="fa-solid fa-crop"
                style="font-size: 0.7rem;margin-right: 5px;"
              />Choisir photo</label>
        </div>
        <Button v-else class="p-button-sm" label="Supprimer" icon="pi pi-trash" style="padding: 3px 12px;font-size: 13px;border-radius: 7px;" @click="clear"></Button>
        <Button v-if="hasUploaded && !isCropped" class="p-button-sm" label="Recadrer" icon="pi pi-camera" style="padding: 3px 12px;font-size: 13px;border-radius: 7px;" @click="cropp"></Button>
        <Button v-if="isCropped" class="p-button-sm" label="Envoyer" icon="pi pi-save" style="padding: 3px 12px;font-size: 13px;border-radius: 7px;" @click="send"></Button>
    </div>
    </div>

    <div style="width: 100%;margin: auto;">
    <div style="position: relative;">
        <div v-show="isCropped" style="margin-top: 16px;"><img id="preview" style="display: none;" width="300"/></div>
        <div v-show="!isCropped" style="margin-top: 16px;background-color: #ddd;max-width: 350px;width: 100%;height: 300px;border-radius: 10px;">
            <img id="image" style="display: none;"/>
        </div>
        
    </div>
    </div>
    </div>
</template>
<script>
import Cropper from 'cropperjs';
import Button from 'primevue/button';

export default {
  name: 'Footer',
  data(){
    return {
        cropper: null,
        isCropped: false,
        hasUploaded: false
    }
  },
  components: {
    Button
  },
  mounted(){

const image = document.getElementById('image');
 this.cropper = new Cropper(image, {
  aspectRatio: 1 / 1,
  crop(event) {
    console.log(event.detail.x);
    console.log(event.detail.y);
    console.log(event.detail.width);
    console.log(event.detail.height);
    console.log(event.detail.rotate);
    console.log(event.detail.scaleX);
    console.log(event.detail.scaleY);
  },
});
  },
  methods:{
    send: function (){
      var canvas =  this.cropper.getCroppedCanvas()
      canvas.toBlob(async (blob) => {
        
        const file = new File([blob], 'canvasImage.jpg', { type: 'image/jpeg' });
      
      // Create a DataTransfer object to hold the File object
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
       // Set the files property of the file input element
       dataTransfer.files;
       console.log(file.type)
        await this.$store.dispatch('uploadAvatar', file)
       this.$emit('close-dialog');
      });
    },
    cropp: function () {
        const preview = document.getElementById('preview');
        this.cropper.getCroppedCanvas()
        preview.src = this.cropper.getCroppedCanvas().toDataURL('image/jpeg')
        preview.style.display = 'block';
        this.isCropped = true;
    },
    clear: function(){
        if (this.cropper) {
        this.cropper.destroy();
      }
      const image = document.getElementById('image');
      image.src = ""
      this.isCropped = false;
      this.hasUploaded = false;
    },
    uploadAvatar: function () {
        console.log('upload avatar')
     const image = document.getElementById('image');
      // Access the selected file
      var files = this.$refs.fileUploadInput.files
      if (files && files.length > 0) {
      const file = files[0];
      const url = URL.createObjectURL(file);
      image.src = url;
      image.style.display = 'block';
      }
      if (this.cropper) {
        this.cropper.destroy();
      }
      this.cropper = new Cropper(image, {
  aspectRatio: 1 / 1,
  minCropBoxWidth: 150,
  minCropBoxHeight: 150,
  crop(event) {
    console.log(event.detail.x);
    console.log(event.detail.y);
    console.log(event.detail.width);
    console.log(event.detail.height);
    console.log(event.detail.rotate);
    console.log(event.detail.scaleX);
    console.log(event.detail.scaleY);
  },
});

this.hasUploaded = true
      //this.$store.dispatch('uploadAvatar', uploadedFile)
      // You can perform additional actions with the file if needed
    },
  }
}

</script>
<style>
img {
  display: block;

  /* This rule is very important, please don't ignore this */
  max-width: 100%;
}
#file-upload-avatar {
  display: none;
}
label{
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
}
label:hover{
    background-color: #ccc;
}
</style>