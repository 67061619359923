<template>
  <div :class="response.icon ? 'card card-icon' : 'card card-list'">
    <div class="box-selector" :class="my_class" v-on:click="selectCard">
      <div class="icon" v-if="response.icon != null">
        <font-awesome-icon :icon="response.icon" />
      </div>
      <div class="label">{{ response.title }}</div>
      <input
        type="radio"
        :id="response.id"
        :name="name"
        :value="response.value"
        v-model="checkedValue"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "CardRadio",
  props: {
    response: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      checkedValue: "1",
      user_response: {
        user_id: "",
        response: {},
        question_id: this.response.question_id,
      },
    };
  },
  computed: {
    name() {
      return "r_" + this.response.question_id;
    },
    my_class() {
      if (this.response.icon != null) {
        return this.checked
          ? "box-selector-height box-selector--selected"
          : "box-selector-height";
      } else {
        return this.checked
          ? "box-selector box-selector--selected"
          : "box-selector";
      }
    },
    ...mapGetters(["auth", "getCurrentItem"]),
    checked() {
      return this.getCurrentItem.user_response.length > 0
        ? this.getCurrentItem.user_response[0].response.id == this.response.id
        : false;
    },
  },
  methods: {
    selectCard() {
      let boxes = document.getElementsByName(this.name);
      
      for (let item of boxes) {
        
        item.parentElement.classList.remove("box-selector--selected");
      }
      let radio = document.getElementById(this.response.id);
      radio.checked = true;
      radio.parentElement.classList.add("box-selector--selected");
      this.user_response.response.id = this.response.id;
      this.user_response.response.value = this.response.value;
      this.user_response.response.next_question_id =
        this.response.next_question_id;
      this.$store.commit("setUserResponse", this.user_response);
    },
  },
};
</script>
<style scoped>
.box-selector {
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  padding: 1.5rem 0.5rem;
  position: relative;
}

.box-selector-height {
}

.box-selector--selected {
  /* Change the border color */
  border: 2px solid #cc1db9;

  /* Used to position the tick */
  position: relative;

  padding-top: 20px;
}
.card-icon {
  width: 40%;
}
.card-list {
  width: 100%;
}
.card {
  text-align: center;
  margin: 10px;
}
.card:hover {
  cursor: pointer;
}
.badge {
  background-color: #d1d5db;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 2px 5px;
  margin: 2px 5px;
}
.flex {
  display: flex;
}
.label {
  bottom: 20%;
  right: 50%;
  left: 0;
  width: 100%;
  margin: auto;
  font-size: 1rem;
  font-weight: 700;
}
input[type="radio"] {
  display: none;
}
.icon {
  position: relative;
  font-size: 2rem;
}
.box-selector--selected .icon {
  color: #cc1db9;
}
</style>
