<template>
<div>
       <h1 class="title mx-auto">Démarrage Rapide !</h1>
    <div class="timeline">
    <!-- Left vertical line -->
    <div class="timeline__line">
        <div class="progress"></div>
    </div>

    <!-- The timeline items timeline -->
    <div class="timeline__items">
         <h3>Démarrage rapide</h3>
        <!-- Each timeline item -->
        <div v-for="(task,index) in tasks" class="timeline__item" :key="task.id">
           <router-link  :class="{'disabled-link': task.status == '2'}" :to="task.action.link">
            <!-- The circle and title -->
            <div class="flex">
            <div class="left">
            <div class="timeline__top">
                <!-- The circle -->
                <div class="timeline__circle" :class="{'done': task.status == '2'}">
                    <span v-if="task.status !=='2' " class="num" >{{index+1}}</span>
                     <span v-else class="absolute left-2 text-white top-1"><font-awesome-icon  icon="fa-solid fa-check" /></span>
                </div>

                <!-- The title -->
                <div class="timeline__title" :class="{'finished': task.status == '2'}">{{task.action.title}}</div>
            </div>

            <!-- The description -->
            <div class="timeline__desc" :class="{'finished': task.status == '2'}">{{task.action.desc}}</div>
            </div>
            <div class="right" v-if="task.status !== '2'">
                <font-awesome-icon icon="fa-solid fa-circle-chevron-right" />
            </div>    
            </div>
           </router-link>
        </div>
        <!-- Repeat other items -->
        
    </div>
</div>
</div>
</template>
<script>
 import { mapGetters} from 'vuex';
export default {
  name: 'TimeLine',
  
  components: {
    
  },
  computed:{
    ...mapGetters(['tasks'])
  },
  data(){
    return{

    }
  },
  mounted: function(){
    this.$store.dispatch('loadTasks');
  }
}
</script>

<style scoped>

.title{
    font-size: 2.5rem;
    font-family: 'serif';
    text-align: left;
    width: 800px;
}
.num{
    position: relative;
top: 6px;
font-size: 1rem;
left: 35%;
font-weight: 600;
color: #fff;
}
.timeline {
    /* Used to position the left vertical line */
    position: relative;
}
.done{
   background: #cc1db9 !important;  
}
.finished{
    text-decoration: line-through;
}
.progress{
    width: 2px;
    height: 20%;
    background: #cc1db9;
    position: absolute;
}
.timeline__line {
    /* Border */
    border-right: 2px solid #d1d5db;

    /* Positioned at the left */
    left: 2.90rem;
    position: absolute;
    top: 88px;

    /* Take full height */
    height: 63%;
}
.timeline__items h3{
    text-align: left;
}
.timeline__items {
    /* Reset styles */
    list-style-type: none;
    margin: 0px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 5px;
}

.timeline__item {
    margin-bottom: 8px;
    padding: 10px;
    text-align: left;
}

.timeline__top {
    /* Center the content horizontally */
    align-items: center;
    display: flex;
}

.timeline__circle {
    /* Rounded border */
    background-color: #d1d5db;
    border-radius: 9999px;

    /* Size */
    height: 2.1rem;
    width: 2.1rem;
    position: relative;
}

.timeline__title {
    /* Take available width */
    flex: 1;
    margin-left: 0.5rem;
    font-weight: 700;
}

.timeline__desc {
    /* Make it align with the title */
    margin-left: 2.7rem;
    color: grey;
}
a{
    text-decoration: none;
    color: #111;
}
a.disabled-link {
  pointer-events: none;
  color: gray;
}
.flex{
    display: flex;
}
.left{
   width: 90%;
}

.right
{
line-height: 4;
position: relative;
width: 10%;
text-align: right;
}
</style>