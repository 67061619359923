<template>
  <div class="sign__in_wrapper">
    <Toast position="top-center" />
    <div class="login">
      <div class="logo"><img src="@/assets/sakina.png" /></div>

      <div class="login-form">
        <span class="error" v-if="form.error">{{ form.error }}</span>
        <Message v-if="form.error" variant="danger" show>
          {{ form.error }}
        </Message>
        <div
          class="mb-1"
          :class="{ 'form-group--error': $v.form.email.$error }"
        >
          <label class="form__label mb-4">Email</label>
          <input
            class="input-field focus:ring-0"
            type="email"
            name="login"
            placeholder="Adresse email"
            v-model="$v.form.email.$model"
            required="required"
          />
          <div class="error" v-if="!$v.form.email.required">
            Ce champs est obligatoire
          </div>
          <div class="error" v-if="!$v.form.email.email">
            Merci de saisir une adresse email
          </div>
        </div>
        <div
          class="mb-1"
          :class="{ 'form-group--error': $v.form.password.$error }"
        >
          <label class="form__label mb-4">Mot de passe</label>
          <input
            class="input-field focus:ring-0"
            type="password"
            name="password"
            placeholder="Mot de passe"
            v-model="$v.form.password.$model"
            required="required"
          />
          <div class="error" v-if="!$v.form.password.required">
            Ce champs est obligatoire
          </div>
        </div>
        <div class="mb-16 flex">
          <input
            class="btn btn-primary custom-button"
            type="button"
            name="sigin"
            value="Se connecter"
            @click="signin"
          />
          <router-link to="/request-new-password" class="ml-24 ln-3"
            >Mot de passe oublié</router-link
          >
        </div>
      </div>
      <div class="mt-50 text-center">
        Vous n'avez pas de compte ?
        <a href="https://www.sakinamariage.com" class="underline"
          >Inscrivez-vous</a
        >
      </div>
    </div>

    <div class="side__image">
      <!--<img src="@/assets/ring-1.jpg"/>-->
    </div>
  </div>
</template>
<script>
import { required, minLength, email } from 'vuelidate/lib/validators'
import * as Sentry from '@sentry/vue'
import Toast from 'primevue/toast'
import Message from 'primevue/message'
export default {
  name: 'Login',
  components: { Message, Toast },
  data() {
    return {
      form: {
        error: null,
        email: '',
        password: '',
      },
    }
  },
  validations: {
    form: {
      email: {
        required,
        email,
        minLength: minLength(4),
      },
      password: {
        required,
        minLength: minLength(4),
      },
    },
  },
  methods: {
    signin() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.form.error = 'ERROR'
      } else {
        this.$store
          .dispatch('signInAction', this.form)
          .then(() => {
            this.$toast.add({
              severity: 'success',
              closable: false,
              summary: 'Vous  êtes connecté',
              life: 10000,
            })
            this.$router.push('/dashboard')
          })
          .catch((error) => {
            Sentry.captureException(error)
            console.log(error.error_description || error.message)
            this.form.error = error.error_description || error.message
          })
      }
    },
  },
}
</script>

<style scoped>
.custom-button {
  background-image: linear-gradient(to bottom, #000000, #303030);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
}

.custom-button:hover {
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.3);
}

.custom-button:active {
  box-shadow: none;
}
.logo {
  width: 400px;
  margin: auto;
}
.logo img {
  width: 80%;
}
.ln-3 {
  line-height: 3;
}
.ml-24 {
  margin-left: 24px;
}
.flex {
  display: flex;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-4 {
  margin-bottom: 4px;
}
.login-form label {
}
.login-form {
  text-align: left;
  width: 350px;
  margin: auto;
}
.input-field {
  width: 100%;
  padding: 8px 12px;
  font-size: 1rem;
  font-weight: 200;
  line-height: 22px;
  color: #111;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.5s;
}
.btn {
  border-radius: 4px;
  padding: 15px;
  border: none;
}

.btn-primary {
  background-color: #111;
  color: #fff;
}

.sign__in_wrapper {
  display: flex;
}
.login {
  width: 50%;
}
.side__image {
  width: 50%;
}
.side__image img {
  width: 100%;
}
.error {
  display: none;
}
.form-group--error .error {
  color: red;
  font-size: 0.75em;
  display: block;
}

.form-group--error {
  animation-name: shakeError;
  animation-fill-mode: forwards;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
}

.form-group--error input,
.form-group--error input:focus,
.form-group--error input:hover {
  border-color: #f79483 !important;
}

.form-group--error .form__label {
  color: red;
}
@keyframes shakeError {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(0.375rem);
  }
  30% {
    transform: translateX(-0.375rem);
  }
  45% {
    transform: translateX(0.375rem);
  }
  60% {
    transform: translateX(-0.375rem);
  }
  75% {
    transform: translateX(0.375rem);
  }
  90% {
    transform: translateX(-0.375rem);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
