import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from '@sentry/vue'
import VueEllipseProgress from 'vue-ellipse-progress'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import './assets/tailwind.css'
import VueTailwind from 'vue-tailwind'
import Handlebars from 'handlebars'
import 'cropperjs/dist/cropper.css'
import 'primevue/resources/themes/saga-blue/theme.css' //theme
import 'primevue/resources/primevue.min.css' //core css
import 'primeicons/primeicons.css' //icons
import TDropdown from 'vue-tailwind/dist/t-dropdown'
import TAlert from 'vue-tailwind/dist/t-alert'
import TTag from 'vue-tailwind/dist/t-tag'
import Vuelidate from 'vuelidate'
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'

import './assets/sakina.css'
const settings = {
  't-dropdown': {
    component: TDropdown,
  },
  't-tag': {
    component: TTag,
  },
  't-alert': {
    component: TAlert,
    props: {
      fixedClasses: {
        wrapper: 'relative flex items-center p-4 border-l-4  rounded shadow-sm',
        body: 'flex-grow',
        close:
          'absolute relative flex items-center justify-center ml-4 flex-shrink-0 w-6 h-6 transition duration-100 ease-in-out rounded  focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        closeIcon: 'fill-current h-4 w-4',
      },
      classes: {
        wrapper: 'bg-blue-50 border-blue-500',
        body: 'text-blue-700',
        close: 'text-blue-500 hover:bg-blue-200',
      },
      variants: {
        danger: {
          wrapper: 'bg-red-50 border-red-500',
          body: 'text-red-700',
          close: 'text-red-500 hover:bg-red-200',
        },
        success: {
          wrapper: 'bg-green-50 border-green-500',
          body: 'text-green-700',
          close: 'text-green-500 hover:bg-green-200',
        },
      },
    },
  },
}
/* add icons to the library */
library.add(fas)

Vue.config.productionTip = false
Vue.use(VueEllipseProgress)
Vue.use(VueTailwind, settings)
Vue.use(Vuelidate)
Vue.use(ToastService)
Vue.use(ConfirmationService)
Vue.use(Handlebars)

Vue.use(PrimeVue, {
  ripple: true,
  theme: 'sakina',
})
/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

const savedState = localStorage.getItem('store')
if (savedState) {
  store.replaceState(JSON.parse(savedState))
}

Sentry.init({
  Vue,
  dsn: 'https://22cb1afcf8f8d96be181ea5f1de74e5e@o4507129262047232.ingest.de.sentry.io/4507129264078928',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^http:\/\/192\.168\.1\.35/,
    /^https:\/\/qmqekbqlfwwwhvmrsxro\.supabase\.co/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app')
