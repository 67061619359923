<template>
  <div v-if="auth != null" class="container">
    <div class="welcome w-full mt-6 mx-auto mb-10">
      <h1 class="title">
        Salam Alaykoum <strong>{{ auth.profile.first_name }} 👋 ! </strong>
      </h1>
      <div
        class="welcome-bg"
        style="
          background-color: rgb(219 234 254);
          padding: 50px;
          border-radius: 10px;
        "
      >
        <p>
          Bienvenue dans ton espace personnel ! Ici, tu pourras gérer ton
          <router-link class="link" :to="{ name: 'UserEdit' }">
            profil
          </router-link>
          et tes recherches pour trouver ton âme-soeur.
        </p>
        <div class="text-right mt-5">
          <router-link class="btn" :to="{ name: 'UserEdit' }">
            Editer mon profil
          </router-link>
        </div>
      </div>
    </div>
    <div class="welcome flex mx-auto my-5">
      <div
        class="w-full flex-1 p-10 text-center mr-3 mb-3"
        style="background-color: rgb(252 231 243); border-radius: 20px"
        v-if="matchs.p1 != null"
      >
        <h1 class="font-bold text-lg mb-2">🔔 Nouveau profil</h1>
        <div>
          <Tag
            v-if="matchs.p1.match_id.enabled"
            value="Profil vérifié"
            icon="pi pi-verified"
            severity="success"
            rounded
          ></Tag>
          <Tag
            v-else
            value="Profil non vérifié"
            icon="pi pi-times-circle"
            severity="warning"
            rounded
          ></Tag>
        </div>
        <div class="my-3">
          <div class="mb-2 font-bold">
            {{ matchs.p1.match_id.first_name }},
            {{ getAge(matchs.p1.match_id.profil_attribute) }}
            ans, {{ getHeight(matchs.p1.match_id.profil_attribute) }}
            cm
          </div>
          <div class="mb-3">
            <Badge
              style="margin-right: 3px"
              :value="
                avg(
                  percent(matchs.p1.total_criterias / 21).toFixed(0),
                  percent(matchs.p2.total_criterias / 21).toFixed(0)
                ) + '%'
              "
              size="small"
              :severity="
                getSeverity(
                  avg(
                    percent(matchs.p1.total_criterias / 21).toFixed(0),
                    percent(matchs.p2.total_criterias / 21).toFixed(0)
                  )
                )
              "
            ></Badge>
          </div>

          <div class="mb-3">
            <Avatar
              :label="
                matchs.p1.match_id.first_name.substring(0, 1).toUpperCase()
              "
              shape="circle"
              size="xlarge"
              style="background-color: #5e8f32; color: #ffffff"
            />
          </div>
          <div>
            <Tag
              v-for="(p, index) in matchs.p1.match_id.profil_attribute.slice(
                0,
                10
              )"
              :key="index"
              rounded
              class="m-1"
            >
              <div class="flex">
                <div v-if="p.attribute.icon != null" class="mr-2">
                  <font-awesome-icon :icon="p.attribute.icon" />
                </div>
                <div>
                  <span>
                    {{
                      getCriteriaValue(p.attribute.name, p.value).label ||
                      p.value
                    }}</span
                  >
                </div>
              </div>
            </Tag>
          </div>
          <div class="mt-5">
            <router-link
              :to="{
                name: 'UserDetail',
                params: { id: matchs.p1.match_id.id },
              }"
              class="mx-auto p-button-rounded p-button text-center"
              label="Démarrer"
              style="padding: 10px 90px"
              icon="pi pi-arrow-right"
              iconPos="right"
              >Voir le profil</router-link
            >
          </div>
        </div>
      </div>
      <div v-else class="w-full flex-1 p-10 text-center mr-3 mb-3"
      style="background-color: rgb(252 231 243); border-radius: 20px">
      <div v-if="this.$store.getters.loadingMatch">
          <div class="mb-3">Merci de patienter nous recherchons votre moitié ...</div>
          <div><ProgressSpinner style="width: 50px; height: 50px"/></div>
      </div>
      <div v-else>
        <div class="mb-3 text-center">
          <div><strong>Aucun profil disponible actuellement !</strong></div>
          <div><img class="mx-auto mt-3" src="@/assets/matching-not-found.svg" width="100"></div>
          
        </div>
      </div>

      </div>
      <div
        class="w-full flex-1 p-10 text-center mb-3"
        style="background-color: rgb(254 243 199); border-radius: 20px"
      >
        <h1 class="font-bold text-lg">Besoin d'aide ?</h1>
        <div class="my-3">
          <div>
            Notre support est disponible par mail 💬 7 jours sur 7 de 10h à 17h
            à
            <a href="mailto:contact@sakinamariage.com" class="font-bold">
              contact@sakinamariage.com</a
            >
          </div>
          <div class="mt-5">
            <Button
              icon="pi pi-at"
              class="p-button-rounded p-button-secondary"
            />
            &nbsp;
            <RouterLink
              :to="{ name: 'Meeting' }"
              icon="pi pi-calendar"
              class="p-button-rounded p-button-success"
            >
              <Button
                icon="pi pi-calendar"
                class="p-button-rounded p-button-success"
              >
              </Button
            ></RouterLink>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tasks && tasks.length > 0" class="welcome flex mx-auto my-5">
      <div
        class="w-full flex-1 p-10 text-center mr-3 mb-3"
        style="background-color: rgb(252 231 243); border-radius: 20px"
      >
        <h1 class="font-bold text-lg">
          🔔 To-do
          
        </h1>

        <div v-for="t in tasks" :key="t.id" class="my-3">
          <div class="mb-2 font-bold">{{ t.action.title }}</div>
          <div>{{ t.action.desc }}</div>
          <router-link
            :to="t.action.link"
            class="mx-auto p-button-rounded p-button text-center"
            label="Démarrer"
            style="padding: 10px 100px"
            icon="pi pi-arrow-right"
            iconPos="right"
            >Démarrer</router-link
          >
        </div>
      </div>
    </div>
    <!--div class="match_card">
      <MatchCard />
    </div>-->
    <div class="welcome flex mx-auto">
      <div
        class="w-full flex-1 p-10 text-center mr-3 mb-3"
        style="background-color: rgb(254 243 199); border-radius: 20px"
      >
        <h1 class="font-bold text-lg">Des profils hautements qualifiés</h1>
        <div class="my-3">
          Tous nos membres sont minitieusement sélectionnés par un de nos
          modérateurs afin de nous assurer du serieux de chaque demande.
        </div>
        <Button
          class="mx-auto w-full p-button-rounded"
          label="En savoir plus"
        ></Button>
      </div>
      <div
        class="w-full flex-1 p-10 text-center mb-3"
        style="background-color: rgb(254 243 199); border-radius: 20px"
      >
        <h1 class="font-bold text-lg">Votre sécurité avant tout</h1>
        <div class="my-3">
          Vos données sont cryptées avec l'algorithme AES-256 qui est le plus
          haut niveau de cryptage.
        </div>
        <Button
          class="mx-auto w-full p-button-rounded"
          label="En savoir plus"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import MatchCard from '../components/MatchCard.vue'
import TimeLine from '../components/TimeLine.vue'
import Button from 'primevue/button'
import ProgressSpinner from 'primevue/progressspinner';
import Badge from 'primevue/badge'
import Avatar from 'primevue/avatar'
import Tag from 'primevue/tag'
import { mapGetters } from 'vuex'

export default {
  name: 'Dashboard',

  components: {
    Button,
    Badge,
    Tag,
    Avatar,
    ProgressSpinner,
  },
  data() {
    return { tasks: [], matchs: [] }
  },
  computed: {
    ...mapGetters(['profil', 'constants', 'auth','loadingMatch']),
  },
  mounted: async function () {
    this.tasks = await this.$store.dispatch('loadTasks', this.auth.id)
    this.matchs = await this.$store.dispatch('loadMatchs', this.auth.id)
  },
  methods: {
    getCriteriaValue: function (name, value) {
      let key = name.toLowerCase()

      if (this.constants[key]) {
        let obj = this.constants[key].filter((c) => {
          return c.value == value
        })[0]

        return obj
      } else {
        return 'NA'
      }
    },
    percent: function (value) {
      if (value <= 1) {
        return value * 100
      }
    },
    avg: function (v1, v2) {
      let result = (parseFloat(v1) + parseFloat(v2)) / 2

      return result
    },
    getSeverity(value) {
      if (value >= 80) return 'success'
      else if (value >= 60) {
        return 'warning'
      } else return 'danger'
    },
    getAge: function (attr) {
      let age = attr.filter((a) => {
        return a.attribute.id == 10
      })[0]
      return age != null ? age.value : 'NA'
    },
    getHeight: function (attr) {
      return attr.filter((a) => {
        return a.attribute.id == 24
      })[0].value
    },
  },
}
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: auto;
  width: 100%;
}
.title {
  font-size: 2.5rem;
  font-family: serif;
}
.match__card {
  height: 400px;
  border-radius: 20px;
  margin: auto;
  display: flex;
  margin-top: 100px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: #fff;
  padding: 1rem;
}
.btn {
  background-color: #cc1db9;
  color: #fff;
  padding: 10px 50px;
  border-radius: 50px;
  border: none;
  font-size: 1.2rem;
  margin: 17px 0px;
}
.item {
  flex: 0 0 50%;
}
img {
  max-width: 100%;
  border-radius: 20px 0px 0px 20px;
}
.details {
  padding: 10px;
  text-align: left;
  line-height: 1.6;
}

.chip {
  /* Center the content */
  align-items: center;
  display: inline-flex;
  justify-content: center;

  /* Background color */
  background-color: #d1d5db;

  /* Rounded border */
  border-radius: 9999px;

  /* Spacing */
  padding: 0.25rem 0.5rem;

  margin-bottom: 5px;
  margin-right: 5px;
  font-size: 0.8rem;
}

.chip__content {
  margin-right: 0.25rem;
}
.label {
  font-weight: 700;
}
.badges {
  margin: 10px 0;
}
.center {
  text-align: center;
}
.back {
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  right: 0;
  z-index: -1;
}
.rotate-5 {
  transform: rotate(5deg);
}
.rotate-7 {
  transform: rotate(7deg);
}
.container {
  position: relative;
}
.timeline,
.match_card {
  margin: 30px auto;
}
.welcome {
  text-align: left;
}
.link {
  color: #cc1db9;
  font-weight: 700;
}

.help {
  background-image: url('../assets/help.png');
  background-size: 170px;
  background-position: 50% 79%;
  background-repeat: no-repeat;
}

.welcome-bg {
  background-image: url('../assets/welcome.png');
  background-size: 22%;
  background-position: 0 205%;
  background-repeat: no-repeat;
}
/* Common styles for all screen sizes */
.title {
  font-size: 2.5rem;
  font-family: serif;
}
</style>
