<template>
  <div>
    <section class="why">
      <div class="container p-5 text-center">
        <!--<h1>Une nouvelle facon de trouver votre moitié</h1>
      <h2>La technologie au service de la religion</h2>
    <img src="https://via.placeholder.com/900x300"/>-->
        <h1>Pourquoi Sakina ?</h1>
        <p>
          <!--Sakina est né de la volonté d'offrir a nos jeunes musulman.e.s souhaitant se marier, une plateforme de mise en relation respectannt les principes de notre religion.
      Il existe beaucoup d'application de rencontre mais très peu respectent les règles religieuses concernant les rencontres entre un homme et une femme.
      Sakina nn'est pas une autre applicatioin de rencontre nous voulons etre plus qu'une application mais une plateforme  de mise en relatioin de suivi et de coachiing.
      Notre objectif est de permettre à tout un chacunn de trouver son ame-soeur et de vivre un mariage heureux. -->
          Sakina est une plateforme dédiée aux musulmans qui souhaitent se
          marier. Nous vous offrons la possibilité d'organiser des rencontres
          dans le respect des principes de notre religion. Sakina n'est pas une
          autre application de rencontre, nous voulons être une plateforme de
          mise en relation et d'accompagnement. Notre objectif est de permettre
          à chacun de trouver l'âme sœur et de vivre un mariage heureux.
        </p>
      </div>
    </section>
    <main class="container">
      <div class="feature text-center">
        <div class="item">
          <div class="img"><img src="@/assets/matching.svg" /></div>
        </div>
        <div class="item">
          <h1><span class="underlined">Algorithme</span> de matching</h1>
          <p>
            Trouvez votre moitié gràce à notre algorithme de matching, nous
            utilisons la technologie afin de trouver un profil compatible avec
            le votre. Notre algorithme mets en correspondance plus de 45
            critères de compatibilité.
          </p>
          <button class="btn">Pré-inscription</button>
        </div>
      </div>

      <div class="feature text-center">
        <div class="item">
          <h1>Profils <span class="underlined">pré-qualifiés</span></h1>
          <p>
            Tous nos membres sont minitieusement sélectionnés. Après le
            questionnaire de pré-sélection visant à déterminer le profil chaque
            membre est ensuite appelé par un de nos modérateurs afin de nous
            assurer du serieux de chaque demande.
          </p>
          <button class="btn">Pré-inscription</button>
        </div>
        <div class="item">
          <div class="img"><img src="@/assets/rania.svg" /></div>
        </div>
      </div>

      <div class="feature text-center">
        <div class="item">
          <div class="img"><img src="@/assets/abou.png" /></div>
        </div>
        <div class="item">
          <h1>Rencontre <span class="underlined">vidéo</span></h1>
          <p>
            Dites bonjour à notre nouvelle fonction d'appel vidéo ! Avec Sakina,
            vous pouvez désormais profiter de toute la sérénité et de
            l'authenticité d'une conversation téléphonique.
          </p>
          <button class="btn">Pré-inscription</button>
        </div>
      </div>

      <div class="feature text-center">
        <div class="item">
          <h1>
            Vos <span class="underlined">données</span> sont notre priorité
          </h1>
          <p>
            Pas d'inquitetude vos données sont en sécurité. Chez Sakina nos
            conservons vos données comme ... Nos systemes sont securisés et
            cryptés niveau xxx qui est le plus haut niveau de cryptage.
          </p>
          <button class="btn">Pré-inscription</button>
        </div>
        <div class="item">
          <div class="img"><img src="@/assets/rgpd.svg" /></div>
        </div>
      </div>
    </main>
    <section class="banner-2">
      <div class="container">
        <!--<h1>Une nouvelle facon de trouver votre moitié</h1>
      <h2>La technologie au service de la religion</h2>
    <img src="https://via.placeholder.com/900x300"/>-->
        <h1>
          Découvrez votre âme sœur sur Sakina, l'application de rencontre et de
          mariage.
        </h1>
        <button class="btn">Pré-inscription</button>
      </div>
    </section>
    <section class="feature">
      <div class="item">
        <img src="@/assets/couple.jpeg" />
      </div>
      <div class="item primary text-white">
        <div>
          <h1>L’application de rencontre Sakina</h1>

          <p>
            Soyez les premiers à expérimenter une nouvelle façon de faire des
            rencontres. C'est l'application qui va révolutionner le monde des
            rencontres et permettre aux musulmans de se rencontrer tout en
            respectant les principes religieux.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Home',

  components: {},
}
</script>
<style scoped>
.why {
  padding: 10px;
  background-color: #cc1db9;
  color: #fff;
}
.banner-2 {
  background-color: #ed147d;
  padding: 10px;
  color: #fff;
}
.underlined {
  background: #cc1db9;
  color: #fff;
  padding: 0 10px;
}
.flex {
  display: flex;
}
.container {
  max-width: 1140px;
  margin: auto;
}
.feature {
  margin: 120px 0;
  display: flex;
}
.img {
  width: 400px;
  height: 400px;
  margin: auto;
}
.item {
  flex: 0 0 50%;
}
img {
  max-width: 100%;
}
.btn {
  background-color: #111;
  color: #fff;
  padding: 20px 50px;
  border-radius: 50px;
  border: none;
  font-size: 1.2rem;
  margin: 17px 0px;
}
.text-white {
  color: #fff;
}
.primary {
  background-color: #cc1db9;
}
.desc {
  width: 600px;
  padding: 1rem;
  font-size: 1.3rem;
  text-align: left;
}
h1 {
  font-size: 3rem;
  font-weight: 700;
}
</style>
