<template>
  <div id="app">
    <AppLayout>
      <router-view />
    </AppLayout>
  </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";

export default {
  name: "App",
  components: {
    AppLayout,
  },
  async created() {
    /* const storeData = localStorage.getItem("store");
    if (storeData) {
      this.$store.replaceState(JSON.parse(storeData));
    }*/
    await this.$store.dispatch("loadUser");
     this.$store.dispatch("loadIndustry");
     this.$store.dispatch("loadRegion");
  },
  async mounted() {
   
  },
};
</script>

<style>
:root {
  --radial-progress-bar-size: 8rem;
  --radial-progress-bar-border-width: 0.75rem;
}

body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  margin: 0px;
}

.side__image {
  background-image: url("./assets/flower.jpeg");
  background-repeat: no-repeat;
  height: 100vh;
  background-size: cover;
}
</style>
