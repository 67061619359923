var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat__container"},[_c('ConfirmDialog'),_c('vue-advanced-chat',{attrs:{"current-user-id":_vm.auth.id,"rooms":JSON.stringify(_vm.rooms),"text-messages":JSON.stringify({
        ROOMS_EMPTY: 'Aucune conversation',
        ROOM_EMPTY: 'Aucune conversation sélectionnée',
        NEW_MESSAGES: 'Nouveaux messages',
        MESSAGE_DELETED: 'Ce message a été supprimé',
        MESSAGES_EMPTY: 'Aucun message',
        CONVERSATION_STARTED: 'La conversation a commencée le :',
        TYPE_MESSAGE: 'Tapez votre message',
        SEARCH: 'Rechercher',
        IS_ONLINE: 'est en ligne',
        LAST_SEEN: 'dernière connexion ',
        IS_TYPING: 'est en train de taper...',
        CANCEL_SELECT_MESSAGE: 'Annuler Sélection',
      }),"room-actions":JSON.stringify(_vm.roomActions),"username-options":JSON.stringify({ minUsers: 2, currentUser: true }),"messages":JSON.stringify(_vm.messages),"show-add-room":"false","rooms-loaded":_vm.roomsLoaded,"messages-loaded":_vm.messagesLoaded,"height":"100vh","single-room":"false","show-footer":_vm.isChatOpen,"show-search":"false","show-audio":"false","show-files":"false"},on:{"room-action-handler":function($event){return _vm.roomActionHandler($event.detail[0])},"typing-message":function($event){return _vm.handlerMessage($event.detail[0])},"send-message":function($event){return _vm.sendMessage($event.detail[0])},"fetch-messages":function($event){return _vm.fetchMessages($event.detail[0])}}},[(_vm.currentRoom.name)?_c('div',{staticClass:"p-4 flex justify-between w-full",attrs:{"slot":"room-header"},slot:"room-header"},[_c('div',{staticClass:"flex"},[_c('Avatar',{staticStyle:{"background-color":"#cc1db9","color":"#ffffff"},attrs:{"label":"S","shape":"circle","size":"large"}}),_c('div',{staticClass:"chat-header"},[_vm._v(_vm._s(_vm.roomName)+" - "+_vm._s(_vm.currentRoom.id))])],1),_c('div',{staticClass:"flex"},[(
            !this.currentRoom.is_private_room && this.isChatOpen == 'true'
          )?_c('div',{staticClass:"mr-5"},[_c('Button',{staticClass:"p-button-sm p-button-danger",attrs:{"label":"Terminer","icon":"pi  pi-times-circle"},on:{"click":function($event){return _vm.terminate()}}})],1):_vm._e(),_c('div',[_c('Button',{staticClass:"p-button-sm",attrs:{"label":"Quitter","icon":"pi pi-sign-out"},on:{"click":function($event){return _vm.exit()}}})],1)])]):_vm._e()]),_c('Dialog',{attrs:{"id":"mahram","visible":_vm.isInviteDialogOpen,"header":"Inviter mon tuteur","modal":true},on:{"update:visible":function($event){_vm.isInviteDialogOpen=$event}}},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"mb-3"},[_c('InputText',{attrs:{"id":"username","type":"email","placeholder":"email","required":""},model:{value:(_vm.mahram.email),callback:function ($$v) {_vm.$set(_vm.mahram, "email", $$v)},expression:"mahram.email"}})],1),_c('div',{staticClass:"mb-3"},[_c('InputText',{attrs:{"id":"email","type":"text","placeholder":"nom d'utilisateur","required":""},model:{value:(_vm.mahram.username),callback:function ($$v) {_vm.$set(_vm.mahram, "username", $$v)},expression:"mahram.username"}})],1),_c('div',{staticClass:"mb-3 w-full"},[_c('Button',{staticClass:"w-full",attrs:{"label":"Envoyer","icon":"pi pi-send"},on:{"click":_vm.invite}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }