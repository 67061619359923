<template>
  <div class="" v-if="true">
    <h1 class="title mx-auto">Félicitation vous avez 1 match 🫶!</h1>
    <div class="container mx-auto mb-10">
      <div class="match__card">
        <div class="photo item">
          <img src="@/assets/DISCOVER.png" />
        </div>
        <div class="details item">
          <div>
            <div><h1 class="text-2xl">Rania</h1></div>
            <div>33 ans, Paris</div>

            <div><span class="label">Ma taille:</span> 165 cm</div>
            <div><span class="label">Mon niveau d'étude :</span> BEP</div>
            <div><span class="label">Activité : </span> Vendeuse</div>

            <div class="badges">
              <div class="chip">
                <div class="chip__content">Sans enfant</div>
              </div>
              <div class="chip">
                <div class="chip__content">Jamais mariée</div>
              </div>
              <div class="chip">
                <div class="chip__content">Ne fume pas</div>
              </div>
              <div class="chip">
                <div class="chip__content">Ne bois pas</div>
              </div>
              <div class="chip">
                <div class="chip__content">Mange Halal</div>
              </div>
              <div class="chip">
                <div class="chip__content">Fait la prière</div>
              </div>
              <div class="chip">
                <div class="chip__content">Porte le hijab</div>
              </div>
            </div>
            <div>
              <AvatarGroup class="mb-3">
                <Avatar
                  label="K"
                  shape="circle"
                  size="large"
                  style="background-color: #9c27b0; color: #ffffff"
                />
                <Avatar
                  label="A"
                  shape="circle"
                  size="large"
                  style="background-color: #b02740; color: #ffffff"
                />
              </AvatarGroup>
            </div>
            <!--<div>
            <h2>Compatibilité</h2>
           <div>Personalité</div>
           <div>Spiritualité Religion</div>
           <div>Famille</div>
           <div>Projets</div>
           <div>Loisirs</div>
           <div>Budget dépense</div>
           </div>-->
            <div class="center">
              <div>
                <Button
                  label="Découvrir"
                  class="w-full p-button-rounded"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="match__card back rotate-5"></div>
      <div class="match__card back rotate-7"></div>
    </div>
  </div>
  <div v-else>
    <div class="w-800 mx-auto flex">
      <div class="item">
        <h1 class="title mx-auto">0 match !</h1>
        <p class="text-left mr-5">
          Merci de patienter, nous recherchons ton ame-soeur ! Des que notre
          algorithme aura trouvé des correspondances, elles apparaitront ici. En
          attendant tu peux compléterer le questionnaire afin d'optimiser ta
          recherche.
        </p>
      </div>
      <div class="item">
        <img
          class="w-96 mx-auto center"
          src="@/assets/matching-in-progress.png"
        />
      </div>
    </div>
  </div>
</template>
<script>
import RadarChart from "../components/RadarChart.vue";
import { VueEllipseProgress } from "vue-ellipse-progress";
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";
import Button from "primevue/button";

export default {
  name: "MatchCard",

  components: {
    Avatar,
    AvatarGroup,
    Button,
  },
};
</script>
<style scoped>
.title {
  font-size: 2.5rem;
  font-family: "serif";
  text-align: left;
}
.match__card {
  width: 800px;
  height: 400px;
  border-radius: 5px;
  margin: auto;
  display: flex;
  margin-top: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: #fff;
}
.btn {
  background-color: #cc1db9;
  color: #fff;
  padding: 10px 50px;
  border-radius: 50px;
  border: none;
  font-size: 1.2rem;
  margin: 17px 0px;
}
.item {
  flex: 0 0 50%;
}
img {
  max-width: 100%;
  /*border-radius: 20px 0px 0px 20px;*/
}
.details {
  padding: 1rem;
  text-align: left;
  line-height: 1.6;
}
.w-800 {
  width: 800px;
}

.chip {
  /* Center the content */
  align-items: center;
  display: inline-flex;
  justify-content: center;

  /* Background color */
  background-color: #d1d5db;

  /* Rounded border */
  border-radius: 9999px;

  /* Spacing */
  padding: 0.25rem 0.5rem;

  margin-bottom: 5px;
  margin-right: 5px;
  font-size: 0.8rem;
}

.chip__content {
  margin-right: 0.25rem;
}
.label {
  font-weight: 700;
}
.badges {
  margin: 10px 0;
}
.center {
  text-align: center;
}
.back {
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  right: 0;
  z-index: -1;
}
.rotate-5 {
  transform: rotate(5deg);
}
.rotate-7 {
  transform: rotate(7deg);
}
.container {
  position: relative;
}
.timeline {
  width: 800px;
  margin: 30px auto;
}
.welcome {
  width: 800px;
  text-align: left;
}
.link {
  color: #cc1db9;
  font-weight: 700;
}
</style>
