<template>
  <div class="chat__container">
    <ConfirmDialog></ConfirmDialog>
    <vue-advanced-chat
      :current-user-id="auth.id"
      :rooms="JSON.stringify(rooms)"
      :text-messages="
        JSON.stringify({
          ROOMS_EMPTY: 'Aucune conversation',
          ROOM_EMPTY: 'Aucune conversation sélectionnée',
          NEW_MESSAGES: 'Nouveaux messages',
          MESSAGE_DELETED: 'Ce message a été supprimé',
          MESSAGES_EMPTY: 'Aucun message',
          CONVERSATION_STARTED: 'La conversation a commencée le :',
          TYPE_MESSAGE: 'Tapez votre message',
          SEARCH: 'Rechercher',
          IS_ONLINE: 'est en ligne',
          LAST_SEEN: 'dernière connexion ',
          IS_TYPING: 'est en train de taper...',
          CANCEL_SELECT_MESSAGE: 'Annuler Sélection',
        })
      "
      :room-actions="JSON.stringify(roomActions)"
      @room-action-handler="roomActionHandler($event.detail[0])"
      :username-options="JSON.stringify({ minUsers: 2, currentUser: true })"
      :messages="JSON.stringify(messages)"
      show-add-room="false"
      :rooms-loaded="roomsLoaded"
      :messages-loaded="messagesLoaded"
      height="100vh"
      single-room="false"
      @typing-message="handlerMessage($event.detail[0])"
      :show-footer="isChatOpen"
      show-search="false"
      show-audio="false"
      show-files="false"
      @send-message="sendMessage($event.detail[0])"
      @fetch-messages="fetchMessages($event.detail[0])"
    >
      <div
        v-if="currentRoom.name"
        slot="room-header"
        class="p-4 flex justify-between w-full"
      >
        <div class="flex">
          <Avatar
            label="S"
            shape="circle"
            size="large"
            style="background-color: #cc1db9; color: #ffffff"
          />
          <div class="chat-header">{{ roomName }} - {{ currentRoom.id }}</div>
        </div>

        <div class="flex">
          <div
            v-if="
              !this.currentRoom.is_private_room && this.isChatOpen == 'true'
            "
            class="mr-5"
          >
            <Button
              label="Terminer"
              icon="pi  pi-times-circle"
              class="p-button-sm p-button-danger"
              @click="terminate()"
            />
          </div>
          <div>
            <Button
              label="Quitter"
              icon="pi pi-sign-out"
              class="p-button-sm"
              @click="exit()"
            />
          </div>
        </div>
      </div>
    </vue-advanced-chat>
    <Dialog
      id="mahram"
      :visible.sync="isInviteDialogOpen"
      header="Inviter mon tuteur"
      :modal="true"
    >
      <div class="flex flex-col">
        <div class="mb-3">
          <InputText
            v-model="mahram.email"
            id="username"
            type="email"
            placeholder="email"
            required
          ></InputText>
        </div>
        <div class="mb-3">
          <InputText
            v-model="mahram.username"
            id="email"
            type="text"
            placeholder="nom d'utilisateur"
            required
          ></InputText>
        </div>
        <div class="mb-3 w-full">
          <Button
            label="Envoyer"
            icon="pi pi-send"
            class="w-full"
            @click="invite"
          ></Button>
        </div>
      </div>
    </Dialog>
  </div>
</template>
<script>
import { register } from 'vue-advanced-chat'
register()
import ConfirmDialog from 'primevue/confirmdialog'
import InputText from 'primevue/inputtext'
import { mapGetters } from 'vuex'
import Tooltip from 'primevue/tooltip'
import moment from 'moment'
import Avatar from 'primevue/avatar'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import { supabase } from '../lib/supabaseClient.js'
export default {
  name: 'ChatRoom',
  components: {
    Avatar,
    Button,
    Dialog,
    ConfirmDialog,
    InputText,
  },
  data() {
    return {
      isChatOpen: 'false',
      mahram: {
        email: '',
        username: '',
      },
      chat_room_channel: null,
      currentRoom: {},
      rooms: [],
      allowedRoom: null,
      isInviteDialogOpen: false,
      roomActions: [{ name: 'inviteMahram', title: 'Inviter Tuteur' }],
      messages: [],
      roomsLoaded: false,
      messagesLoaded: false,
    }
  },
  computed: {
    ...mapGetters(['auth', 'profil', 'regions', 'constants']),
    isMe() {
      return this.auth.id == this.$route.params.id
    },
    isAdmin() {
      return this.auth.user_metadata.role == 1
    },
    roomName() {
      if (this.currentRoom.is_private_room) return this.currentRoom.name
      else {
        return this.currentRoom.chat_room_users.reduce(
          (acc, curr, index, array) => {
            const separator = index < array.length - 1 ? ', ' : ', '
            return acc + (index > 0 ? separator : '') + curr.profiles.first_name
          },
          ''
        )
      }
    },
  },
  directives: {
    tooltip: Tooltip,
  },
  mounted: async function () {
    if (this.auth.user_metadata.gender == '1') {
      this.roomActions = []
    }

    var rooms = await this.$store.dispatch('loadRooms')
    this.currentRoom = rooms[0]
    this.allowedRoom =
      rooms.filter((r) => !r.is_private_room && r.status == 'OPEN')[0] ||
      this.currentRoom
    this.rooms = rooms.map((r) => ({
      roomId: r.id,
      status: r.status,
      roomName: r.name,
      index: r.last_message != null ? r.last_message._id : 0,
      avatar: 'https://sakinamariage.com/favicon.ico',
      lastMessage: r.last_message,
      users: r.chat_room_users.map((u) => ({
        _id: u.profiles.id,
        username: u.profiles.first_name,
      })),
      //users:[{_id:r.invitation.match_id.id,username:r.invitation.match_id.first_name},{_id:r.invitation.profil_id.id,username:r.invitation.profil_id.first_name},{_id:"aadd9303-7f85-4fd9-9d13-84447fbe28d0",username:"AAK"}]
    }))
    this.roomsLoaded = true
    if (this.currentRoom.is_private_room) {
      this.isChatOpen = 'true'
    } else this.setChatTimer()

    if (this.chat_message_channel != null) {
      supabase.removeChannel(this.chat_message_channel)
    }
    if (this.chat_room_channel != null) {
      supabase.removeChannel(this.chat_room_channel)
    }
    this.chat_message_channel = supabase
      .channel('chat_message_channel')
      .on(
        'postgres_changes',
        { event: 'INSERT', schema: 'public', table: 'chat_message' },
        (payload) => {
          //console.log('Change received!', payload)
          var msg = {
            _id: payload.new.id,
            content: payload.new.content,
            senderId: payload.new.sender_id,
            seen: false,
            distributed: true,
            roomId: payload.new.room_id,
            system: payload.new.system,
            username: payload.new.username,
            date: moment(payload.new.created_at).format('DD MMM'),
            timestamp: moment(payload.new.created_at).format('HH:mm'),
          }
          if (payload.new.room_id == this.currentRoom.id) {
            this.messages.push(msg)

            this.currentRoom.lastMessage = msg
          }
          let room = {
            id: payload.new.room_id,
            last_message: msg,
          }
          let room_tmp = this.rooms.filter(
            (r) => r.roomId === payload.new.room_id
          )[0]
          if (room_tmp) {
            room_tmp.lastMessage = msg
            room_tmp.index = payload.new.id
            
            // this.rooms.sort((a,b) => a.lastMessage.created_at - b.lastMessage.created_at)
            this.$store.dispatch('updateRoomLastMessage', room)
          }
          //this.$store.dispatch('updateRoomLastMessage',room)
        }
      )
      .subscribe()

      // chat room channel
      this.chat_room_channel = supabase
      .channel('chat_room_channel')
      .on(
        'postgres_changes',
        { event: 'UPDATE', schema: 'public', table: 'chat_room' },
        async (payload) => {
          if (payload.new.id == this.currentRoom.id) {
          var rooms = await this.$store.dispatch('loadRooms')
            this.allowedRoom =
              rooms.filter(
                (r) => !r.is_private_room && r.status == 'OPEN'
              )[0] || 0
            
            this.rooms = rooms.map((r) => ({
              roomId: r.id,
              status: r.status,
              roomName: r.name,
              avatar: 'https://sakinamariage.com/favicon.ico',
              lastMessage: r.last_message,
              users: r.chat_room_users.map((u) => ({
                _id: u.profiles.id,
                username: u.profiles.first_name,
              })),
              //users:[{_id:r.invitation.match_id.id,username:r.invitation.match_id.first_name},{_id:r.invitation.profil_id.id,username:r.invitation.profil_id.first_name},{_id:"aadd9303-7f85-4fd9-9d13-84447fbe28d0",username:"AAK"}]
            }))
            this.fetchMessages({ room: { roomId: this.currentRoom.id } })
          }
        }
      )
      .subscribe()
  },
  methods: {
    setChatTimer() {
      // Current time
      const now = new Date()

      // Target time today (e.g., 18:00)
      //  const targetHour = 18; // 24-hour format
      const start = new Date()
      start.setHours(18, 0, 0, 0) // Set to 18:00
      const end = new Date()
      end.setHours(23, 0, 0, 0) // Set to 18:00
      // Calculate delay
      let delay_before_chat_opens = start - now
      let delay_before_chat_ends = end - now
      let hasChatStarted = start - now < 0
      let hasChatEnded = end - now < 0
      // en dehors des heures d'ouverture
      if (hasChatEnded) {
        // If target time is past, schedule for the next day
        this.isChatOpen = 'false'
        delay_before_chat_opens += 24 * 60 * 60 * 1000 // Add 24 hours in milliseconds
        setTimeout(() => {
          this.isChatOpen = 'true'
        }, delay_before_chat_opens)
      } else if (hasChatStarted && !hasChatEnded) {
        this.isChatOpen = 'true'
        setTimeout(() => {
          this.isChatOpen = 'false'
        }, delay_before_chat_ends)
      } else {
        // Set a timer to update the message
        this.isChatOpen = 'false'
        setTimeout(() => {
          this.isChatOpen = 'true'
        }, delay_before_chat_opens)
        setTimeout(() => {
          this.isChatOpen = 'false'
        }, delay_before_chat_ends)
      }
    },
    maskPhoneNumbersAndEmails(text) {
      // Regular expression pattern for matching French phone numbers
      const frenchPhoneNumberPattern = /(?:\+33|0)[1-9](?:[\s.-]?[0-9]{2}){4}/g

      // Regular expression pattern for matching email addresses
      const emailPattern = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/g

      // First, replace all French phone numbers with X's
      let maskedText = text.replace(frenchPhoneNumberPattern, (match) =>
        'X'.repeat(match.length)
      )

      // Then, replace all email addresses with X's
      maskedText = maskedText.replace(emailPattern, (match) =>
        'X'.repeat(match.length)
      )

      return maskedText
    },
    terminate() {
      this.$confirm.require({
        message: `Souhaitez-vous terminer ce chat, il sera automatiquement archivé ?`,
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: async () => {
          //callback to execute when user confirms the action
          try {
            await this.$store.dispatch('terminateChatRoom', this.currentRoom.id)
            this.$toast.add({
              severity: 'success',
              closable: false,
              summary: 'Chat terminé',
              detail: 'Votre chat sera archivé',
              life: 3000,
            })
           /* var rooms = await this.$store.dispatch('loadRooms')
            this.allowedRoom =
              rooms.filter(
                (r) => !r.is_private_room && r.status == 'OPEN'
              )[0] || 0
            
            this.rooms = rooms.map((r) => ({
              roomId: r.id,
              status: r.status,
              roomName: r.name,
              avatar: 'https://sakinamariage.com/favicon.ico',
              lastMessage: r.last_message,
              users: r.chat_room_users.map((u) => ({
                _id: u.profiles.id,
                username: u.profiles.first_name,
              })),
              //users:[{_id:r.invitation.match_id.id,username:r.invitation.match_id.first_name},{_id:r.invitation.profil_id.id,username:r.invitation.profil_id.first_name},{_id:"aadd9303-7f85-4fd9-9d13-84447fbe28d0",username:"AAK"}]
            }))
            this.fetchMessages({ room: { roomId: this.currentRoom.id } })*/
          } catch (error) {
            this.$toast.add({
              severity: 'error',
              closable: false,
              summary: 'Vous ne pouvez plus inviter ' + this.user.first_name,
              detail: this.user.first_name + ' a déja une invitation en cours.',
              life: 3000,
            })
          }
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      })
    },

    exit: function () {
      supabase.removeChannel(this.chat_room_channel)
      this.$router.push('/')
    },
    roomActionHandler({ roomId, action }) {
      
      switch (action.name) {
        case 'inviteMahram':
          this.isInviteDialogOpen = true
          // call a method to archive the room
          break
      }
    },

    async invite() {
      await this.$store.dispatch('inviteTuteur', {
        email: this.mahram.email,
        roomId: this.currentRoom.id,
        username: this.mahram.username,
      })
      this.isInviteDialogOpen = false
    },
    async fetchMessages({ room, options }) {
      //console.log('222222')

      this.messagesLoaded = false
      this.currentRoom = await this.$store.dispatch('loadOneRoom', room.roomId)

      if (this.currentRoom.id == this.allowedRoom.id) {
        this.setChatTimer()

        var msg = await this.$store.dispatch(
          'loadMessages',
          this.currentRoom.id
        )
        this.messages = msg.map((m) => ({
          _id: m.id,
          senderId: m.sender_id,
          username: m.username,
          content: m.content,
          reactions: m.reactions,
          system: m.system,
          date: moment(m.created_at).format('DD MMM'),
          timestamp: moment(m.created_at).format('HH:mm'),
          distributed: m.distributed,
          replyMessage: m.replyMessage,
        }))

        if (this.allowedRoom.is_private_room) {
          this.isChatOpen = 'true'
        }
        // use timeout to imitate async server fetched data
        //this.messagesLoaded = true
        if (this.isChatOpen == 'false') {
          this.messages.push({
            _id: Date.now.toString(),
            senderId: '1',
            content:
              '👋 Salam 3alaykoum!  Le chat est actuellement fermé, pour rappel : ⏰ Lun-Dim de 18H à 22H.',
            username: 'Admin',
            system: true,
            roomId: this.currentRoom.id,
            date: new Date().toDateString(),
            timestamp: new Date().toString().substring(16, 21),
          })
        } else {
          this.messages.push({
            _id: Date.now.toString(),
            senderId: '1',
            content:
              "⚠️ Ce chat est supervisé par un membre de l'équipe Sakina",
            username: 'Admin',
            system: true,
            roomId: this.currentRoom.id,
            date: new Date().toDateString(),
            timestamp: new Date().toString().substring(16, 21),
          })
        }
      } else {
        this.setChatTimer()
        msg = await this.$store.dispatch('loadMessages', this.currentRoom.id)
        this.messages = msg.map((m) => ({
          _id: m.id,
          senderId: m.sender_id,
          username: m.username,
          content: m.content,
          reactions: m.reactions,
          system: m.system,
          date: moment(m.created_at).format('DD MMM'),
          timestamp: moment(m.created_at).format('HH:mm'),
          distributed: m.distributed,
          replyMessage: m.replyMessage,
        }))
        
        this.isChatOpen = 'false'
        if (this.currentRoom.is_private_room || this.isAdmin) {
          this.isChatOpen = 'true'
        }
        if (this.currentRoom.status == 'CLOSE') {
          this.isChatOpen = false
          this.messages.push({
            _id: Date.now().toString(),
            senderId: '1',
            content: '⚠️ Cette conversation a été archivée.',
            username: 'Admin',
            system: true,
            roomId: this.currentRoom.id,
            date: new Date().toDateString(),
            timestamp: new Date().toString().substring(16, 21),
          })
         
        } else if (this.isChatOpen == 'false') {
          this.messages.push({
            _id: Date.now().toString(),
            senderId: '1',
            content:
              '🔔 Ce chat sera accessible, une fois votre conversation en cours terminée.',
            username: 'Admin',
            system: true,
            roomId: this.currentRoom.id,
            date: new Date().toDateString(),
            timestamp: new Date().toString().substring(16, 21),
          })
         
        }
      }
      setTimeout(() => {
        this.messagesLoaded = true
      }, 1000)
    },
    handlerMessage({ roomId, message }) {
      
      this.currentRoom.typingUsers = [this.auth.id]
    },
    sendMessage(message) {
      
      var msg = {
        _id: Date.now(),
        content: this.maskPhoneNumbersAndEmails(message.content),
        senderId: this.auth.id,
        username: this.auth.user_metadata.firstName,
        seen: false,
        distributed: false,
        system: false,
        roomId: this.currentRoom.id,
        new: true,
        timestamp: new Date().toString().substring(16, 21),
        date: new Date().toDateString(),
      }
      this.currentRoom.lastMessage = msg

      this.$store.dispatch('sendMessage', msg)
    },
  },
}
</script>

<style scoped>
.chat__container {
  margin: auto;
  width: 100%;
  height: 100vh;
}
</style>
