<template>
    <Radar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>
<script>
import { Radar } from 'vue-chartjs/legacy'
// import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
export default {
  name: 'RadarChart',
  components: { Radar },
  props: {
    chartId: {
      type: String,
      default: 'radar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chartData: {
        labels: [ 'Personalité', 'Religion', 'Argent', 'Education', 'Loisir', 'Famille' ],
        datasets: [ 
            { label: 'Rania', data: [40, 67, 78 ,30 ,55 ,90], backgroundColor: 'rgba(255, 99, 132, 0.2)', borderColor: 'rgb(255, 99, 132)', fill: true},
            { label: 'Mehdi', data: [40, 99, 25 ,30 ,89 ,90], backgroundColor: 'rgba(54, 162, 235, 0.2)',  borderColor: 'rgb(54, 162, 235)', fill: true} ]
      },
      chartOptions: {
        responsive: true
      }
    }
  }
}
</script>

<style scoped>

</style>