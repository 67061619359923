<template>
    <div class="container">
<div class="progress">
 <vue-ellipse-progress :progress="progress" :size="170" color=#cc1db9>
    <p slot="legend-caption">%</p>
</vue-ellipse-progress>
</div>


    </div>
</template>
<script>
import { VueEllipseProgress } from "vue-ellipse-progress";
export default {
  name: 'SurveySidebar',
  components: {
    VueEllipseProgress
  },
  computed:{
    progress(){
        console.log(this.$store.getters.progress);
        return this.$store.getters.progress;
    }
  }
}
</script>
<style scoped>
.progress{
margin: auto;
margin-top:30px
}

.container{
    width: 100%;
    position: relative;
    height: 100%;
    right: 0;
    border-left: 1px solid #eee;
}
.steptrack{
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 10px;
}
.fl-1{
    flex:1;
}
</style>