<template>
  <div class="card">
    <div
      class="box-selector"
      v-on:click="selectCard"
      :class="{ 'box-selector--selected': selected.includes(response.id) }"
    >
      <div class="label">{{ response.title }}</div>
      <input
        type="checkbox"
        :id="response.id"
        :name="name"
        :value="response.value"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Checkbox",
  props: {
    response: {
      type: Object,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getCurrentItem"]),
    name() {
      return "r1";
    },
    isPreviousIsNan() {
      return (
        this.getCurrentItem.user_response.length > 0 &&
        this.getCurrentItem.user_response[0].response.value == "NA"
      );
    },
  },
  data() {
    return {
      user_response: {
        user_id: "",
        response: {},
        question_id: this.response.question_id,
      },
    };
  },
  methods: {
    selectCard() {
      let radio = document.getElementById(this.response.id);
     
      if (this.response.value == "NA") {
        if (radio.parentElement.classList.contains("box-selector--selected")) {
          radio.checked = false;
          radio.parentElement.classList.remove("box-selector--selected");
          this.user_response.response.id = this.response.id;
          this.user_response.response.value = this.response.value;
          this.user_response.response.next_question_id =
            this.response.next_question_id;
          this.$store.commit(
            "removeUserResponse",
            this.user_response.response.id
          );
        } else {
          var els = document.querySelectorAll(".box-selector--selected");
          [].forEach.call(els, function (el) {
            el.classList.remove("box-selector--selected");
          });
          radio.parentElement.classList.add("box-selector--selected");
          this.user_response.response.id = this.response.id;
          this.user_response.response.value = this.response.value;
          this.user_response.response.next_question_id =
            this.response.next_question_id;
          this.$store.commit("removeAllUserResponse");
          this.$store.commit("setUserResponse", this.user_response);
        }
      } else {
        if (radio.parentElement.classList.contains("box-selector--selected")) {
          radio.checked = false;
          radio.parentElement.classList.remove("box-selector--selected");
          this.user_response.response.id = this.response.id;
          this.user_response.response.value = this.response.value;
          this.user_response.response.next_question_id =
            this.response.next_question_id;
          this.$store.commit(
            "removeUserResponse",
            this.user_response.response.id
          );
        } else {
          if (this.isPreviousIsNan) {
            var elss = document.querySelectorAll(".box-selector--selected");
            [].forEach.call(elss, function (el) {
              el.classList.remove("box-selector--selected");
            });
            radio.parentElement.classList.add("box-selector--selected");
            this.user_response.response.id = this.response.id;
            this.user_response.response.value = this.response.value;
            this.user_response.response.next_question_id =
              this.response.next_question_id;
            this.$store.commit("removeAllUserResponse");
            this.$store.commit("setUserResponse", this.user_response);
          } else {
            radio.checked = true;
            radio.parentElement.classList.add("box-selector--selected");
            this.user_response.response.id = this.response.id;
            this.user_response.response.value = this.response.value;
            this.user_response.response.next_question_id =
              this.response.next_question_id;
            this.$store.commit("setUserResponse", this.user_response);
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.box-selector {
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  padding: 1.5rem 0.5rem;
  position: relative;
}

.box-selector--selected {
  /* Change the border color */
  background: #cc1db9;
  border: 1px solid #cc1db9;
  color: #fff;
  /* Used to position the tick */
  position: relative;

  padding-top: 20px;
}

.card {
  width: 100%;
  text-align: center;
  margin: 10px;
}
.card:hover {
  cursor: pointer;
}
.badge {
  background-color: #d1d5db;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 2px 5px;
  margin: 2px 5px;
}
.flex {
  display: flex;
}
.label {
  position: absolute;
  bottom: 20%;
  right: 50%;
  left: 0;
  width: 100%;
  margin: auto;
  font-size: 1rem;
  font-weight: 700;
}
input[type="checkbox"] {
  display: none;
}
.icon {
  position: relative;
  margin-top: 10%;
  font-size: 3rem;
}
.box-selector--selected .icon {
  color: #cc1db9;
}
</style>
