var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading == false)?_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title"},[_vm._v("Résumé de votre questionnaire")]),_c('t-alert',{staticClass:"text-left",attrs:{"dismissible":false,"show":""}},[_vm._v("Merci de vérifier les réponses du questionnaire avant validation."),_c('br')]),_c('div',{staticClass:"sticky top-0 z-50 mt-3"},[_c('button',{staticClass:"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4",on:{"click":_vm.sendResponse}},[_vm._v(" Valider mes réponses ")])]),_vm._l((_vm.questions),function(q,index){return _c('div',{key:q.index,staticClass:"question__card"},[_c('div',{staticClass:"question"},[_c('h1',{staticClass:"q"},[_vm._v(_vm._s(index + 1)+") "+_vm._s(q.title))])]),_c('div',{staticClass:"answer"},[(
          q.choices.length == 0 &&
          (q.response_type == 'SHORT_ANSWER' ||
            q.response_type == 'TEXT' ||
            q.response_type == 'DATE')
        )?_c('div',[_vm._v(" "+_vm._s(q.user_response[0].value)+" "),_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-pen-to-square"},on:{"click":function($event){return _vm.edit(q.index)}}})],1):(q.response_type == 'RANGE')?_c('div',[_vm._v(" Entre "+_vm._s(q.user_response[0].min_value)+" et "+_vm._s(q.user_response[0].max_value)+" "),_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-pen-to-square"},on:{"click":function($event){return _vm.edit(q.index)}}})],1):(q.response_type == 'REGION')?_c('div',[_vm._v(" "+_vm._s(_vm.regions.filter((r) => r.value == q.user_response[0].value)[0].name)+" "),_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-pen-to-square"},on:{"click":function($event){return _vm.edit(q.index)}}})],1):_c('div',[_vm._l((q.user_response),function(a,index){return _c('Tag',{key:index,staticClass:"mx-2",attrs:{"value":q.choices.filter(
              (c) => a.response != null && c.id == a.response.id
            )[0].title}})}),_vm._v("    "),_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-pen-to-square"},on:{"click":function($event){return _vm.edit(q.index)}}})],2)])])})],2):_c('div',[_c('ProgressStep')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }