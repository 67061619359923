<template>
<div class="form-wrapper">
    <h1 class="title">Veuillez patienter...</h1>
    <ul class="animation animation--fixed" data-step-animation="true" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;padding: 25px;border-radius: 10px;">
        <li class="animation__line"><div class="animation__loader ">
            <div class="animation__loader-item"></div></div>
            <i class="animation__icon material-icons"><font-awesome-icon icon="fa-solid fa-check" /></i>
            <span class="animation__label">Création de votre bio</span>
        </li>
        <li class="animation__line"><div class="animation__loader ">
            <div class="animation__loader-item"></div></div>
            <i class="animation__icon material-icons"><font-awesome-icon icon="fa-solid fa-check" /></i>
            <span class="animation__label">Création de votre description physique</span>
        </li>
        <li class="animation__line"><div class="animation__loader ">
            <div class="animation__loader-item"></div></div>
            <i class="animation__icon material-icons"><font-awesome-icon icon="fa-solid fa-check" /></i>
            <span class="animation__label">Création de vos critères de recherche</span>
        </li>
        <li class="animation__line"><div class="animation__loader">
            <div class="animation__loader-item"></div></div>
            <i class="animation__icon material-icons"><font-awesome-icon icon="fa-solid fa-check" /></i>
            <span class="animation__label">Calcule score questionnaire</span>
        </li>
    </ul>
</div>
</template>
<script>
export default {
  name: 'ProgressStep',
  props: {
    
  },
  components: {
  },
  data(){
    return {
       
    }
  },
  computed:{
  }
}
</script>
<style scoped>
.title{
    font-size:3rem;
    text-align: center;
    font-family: 'Serif';
}
.form-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
  padding: 0 0 9.6rem;
  position: relative;
}
.animation--fixed {
  margin: -4.9333333333rem auto 0;
  right: 33%;
  top: 20%;
  width: calc(100% - 4.26667rem);
}
.animation__line:first-child .animation__label {
  animation: animOpacity .3s ease-out .5s forwards;
}
.animation__line:nth-child(2) .animation__label {
  animation: animOpacity .3s ease-out 2.9s forwards;
}
.animation__line:nth-child(3) .animation__label {
  animation: animOpacity .3s ease-out 4.9s forwards;
}
.animation__line:nth-child(4) .animation__label {
  animation: animOpacity .3s ease-out 6.9s forwards;
}
.animation__label {
  display: block;
  opacity: .2;
  padding-left: 4.2666666667rem;
}
.animation {
  /*left: 0;*/
  list-style: none;
  margin: auto;
  max-width: 29.3333333333rem;
}


.animation__line:not(:last-child) {
  margin-bottom: 2.1333333333rem;
}
.animation__line {
  align-items: center;
  display: flex;
  font-size: 1.1333333333rem;
  height: 2.6666666667rem;
  position: relative;
}
.animation__line:first-child .animation__loader {
  animation: animLoader .3s ease-in .5s forwards;
}
.animation__line:nth-child(2) .animation__loader {
  animation: animLoader .3s ease-in 2.9s forwards;
}
.animation__line:nth-child(3) .animation__loader {
  animation: animLoader .3s ease-in 4.9s forwards;
}
.animation__line:nth-child(4) .animation__loader {
  animation: animLoader .3s ease-in 6.9s forwards;
}
.animation__loader {
  opacity: 0;
  top: 2px;
}

.animation__icon, .animation__loader {
  height: 2.6666666667rem;
  left: 0;
  position: absolute;
  width: 2.6666666667rem;
  text-align: center;
}
.animation__line:first-child i {
  animation: animChecked .3s cubic-bezier(.11,.6,.33,1.4) 2s forwards;
}

.animation__line:nth-child(2) i {
  animation: animChecked .3s cubic-bezier(.11,.6,.33,1.4) 4.2s forwards;
}

.animation__line:nth-child(3) i {
  animation: animChecked .3s cubic-bezier(.11,.6,.33,1.4) 6.2s forwards;
}

.animation__line:nth-child(4) i {
  animation: animChecked .3s cubic-bezier(.11,.6,.33,1.4) 8.2s forwards;
}
.animation__icon, .animation__loader {
  height: 2.6666666667rem;
  left: 0;
  position: absolute;
  width: 2.6666666667rem;
}
.animation__icon {
  color: #1e91ff;
  margin-right: 1.0666666667rem;
  padding: .5333333333rem;
  top: 0;
  transform: scale(0);
  vertical-align: middle;
}

.animation__loader-item {
  animation: animRotate .7s linear infinite;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #1e91ff;
  height: 100%;
  width: 100%;
}
.animation__line:first-child .animation__loader > .animation__loader-item::before {
  animation: animWhiteEl .3s linear 1.8s forwards;
}
.animation__line:nth-child(2) .animation__loader > .animation__loader-item::before {
  animation: animWhiteEl .3s linear 4.2s forwards;
}
.animation__line:nth-child(3) .animation__loader > .animation__loader-item::before {
  animation: animWhiteEl .3s linear 6.2s forwards;
}
.animation__line:nth-child(4) .animation__loader > .animation__loader-item::before {
  animation: animWhiteEl .3s linear 8.2s forwards;
}
.animation__loader-item::before {
  background: #fff;
  border-radius: 50%;
  content: "";
  display: block;
  height: 15px;
  left: 12px;
  position: absolute;
  top: -5px;
  transform: scale(1);
  width: 15px;
}
@keyframes animChecked {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

@keyframes animOpacity {
    from {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}

@keyframes animRotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes animLoader {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes animWhiteEl {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(0);
    }
}
</style>