<template>
  <div v-if="surveyItem.length > 0">
    <div><Stepper /></div>
    <div class="flex">
      <div class="question">
        <Section />
        <div class="flex survey_button text-center">
          <a
            class="fl1"
            href=""
            style=""
            @click.prevent="previous"
            :class="[
              { back_button: true },
              { disabled: currentSurveyItem == 0 },
            ]"
          >
            <span
              style="background: #ddd; padding: 5px 20px; border-radius: 5px"
              >Retour</span
            ></a
          >
          <div class="fl1" v-if="this.surveyCompleted">
            <button
              class="btn next_button"
              @click="end"
              :class="{ disabled: hasUserResponse == false }"
            >
              Terminer
            </button>
          </div>
          <!--<div class="fl1"> <button class="btn w-60" @click="recap">Retour au recap</button></div>-->
          <div class="fl1">
            <button
              class="btn next_button"
              @click="next"
              :class="{ disabled: hasUserResponse == false }"
            >
              {{ nextLabel }}
            </button>
          </div>
        </div>
      </div>
      <div class="side text-center"><SurveySidebar /></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Stepper from '../components/Stepper.vue'
import Section from '../components/SurveySection.vue'
import SurveySidebar from '../components/SurveySidebar.vue'

export default {
  name: 'Survey',
  components: {
    Stepper,
    Section,
    SurveySidebar,
  },
  data() {
    return {
      endOfSurvey: false,
    }
  },
  computed: {
    ...mapGetters({
      currentSurveyItem: 'currentSurveyItemIndex',
      hasUserResponse: 'hasUserResponse',
      surveyItem: 'getSurveyItem',
      surveyCompleted: 'survey_completed',
    }),
    nextLabel() {
      if (this.surveyItem.length - 1 == this.currentSurveyItem)
        return 'Terminer'
      else {
        return 'Suivant'
      }
    },
  },
  mounted() {
    setTimeout(async () => {
      this.$store.dispatch('loadSurvey', 'cd06ef1c-1f42-4a58-b553-d76812858ce5')
    }, 1000)
  },
  methods: {
    next() {
      if (this.surveyItem.length - 1 != this.currentSurveyItem) {
        this.$store.commit('nextQuestion')
      } else {
        this.$router.push({
          name: 'SurveyResume',
          params: { id: this.$store.state.survey_response.id },
        })
      }
    },
    end() {
      this.$router.push({
        name: 'SurveyResume',
        params: { id: this.$store.state.survey_response.id },
      })
    },
    previous() {
      this.$store.commit('previousQuestion')
    },
  },
}
</script>

<style scoped>
.flex {
  display: flex;
  position: relative;
}
.question {
  width: 78%;
}
.side {
  width: 20%;
}
.fl1 {
  flex: 1;
}
.btn {
  background-color: #cc1db9;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  padding: 10px;
  border: none;
}
.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
</style>
