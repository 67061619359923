<template>
  <div v-if="user != null" class="profil">
    <ConfirmDialog></ConfirmDialog>
    <div class="header">
      <div class="header-img"><img src="@/assets/sakina-hero-2.png" /></div>
      <div class="w-full profil-desc">
        <div class="main-photo flex-initial">
          <img
            v-if="avatar_link == null"
            src="https://placehold.co/300x300"
            class="border-4 border-white border-solid rounded-xl"
          />
          <img
            v-else
            :src="avatar_link"
            class="border-4 border-white border-solid rounded-xl"
          />
        </div>
        <div>
          <Message
            v-if="hasSentLike()"
            severity="success"
            :closable="false"
          >
            Vous avez aimé le profil de {{ user.first_name }}
          </Message>
          <Message
            v-if="hasSentDislike()"
            severity="error"
            :closable="false"
          >
            Vous n'avez pas aimé le profil de {{ user.first_name }}
          </Message>
        </div>
        <div>
          <Message
            id="invit"
            v-if="hasReceivedLike()"
            severity="success"
            :closable="false"
            class="invit-msg"
          >
            <span>{{ user.first_name }} a aimé votre profil.</span>
          </Message>
          <Message
            v-if="hasReceivedDislike()"
            severity="error"
            :closable="false"
          >
            {{ user.first_name }} n'a pas aimé votre profil
          </Message>
        </div>
        <div v-if="user.enabled == true" class="my-5">
          <Tag
            value="Profil vérifié"
            icon="pi pi-verified"
            severity="success"
            rounded
          ></Tag>
        </div>
        <div v-else class="my-5">
          <Tag
            value="Profil non vérifié"
            icon="pi pi-times-circle"
            severity="warning"
            rounded
          ></Tag>
        </div>
        <div class="flex my-3">
          <div class="mr-3">
            <Button
              @click="interested()"
              label="Je suis interessé(e)"
              icon="pi pi-thumbs-up"
              class="p-button-outlined p-button-success"
              :disabled="hasSentLike()"
            ></Button>
          </div>
          <div>
            <Button
              @click="openRejectModal()"
              label="Je ne suis pas interessé(e)"
              icon="pi pi-thumbs-down"
              class="p-button-outlined p-button-danger"
              :disabled="hasSentDislike()"
            ></Button>
          </div>
        </div>
        <div class="introduction flex-initial text-left mt-5">
          <div class="flex" style="align-items: center">
            <span class="first_name mx-2"
              >{{ user.first_name }}, {{ age }} ans,
              {{ getHeight(user.profil_attribute) }} cm</span
            >
            <!--<div
              style="
                margin-right: 5px;
                width: 13px;
                height: 13px;
                border-radius: 50%;
                background-color: #009900;
              "
            ></div>
            <span>Online</span>-->
          </div>
          <div class="my-3">
            <div class="flex">
              <div v-if="user.heigth" class="mx-2">{{ user.heigth }} cm</div>
              <div v-if="region" class="mx-2">
                <Tag :value="region">
                  <font-awesome-icon icon="fa-solid fa-location-dot" />&nbsp;
                  {{ region }}
                </Tag>
              </div>
            </div>
            <div class="my-5" v-if="user != null">
              <span class="desc">
                <Tag
                  v-for="(c, index) in user.profil_attribute.filter((r) => {
                    return (
                      (r.attribute.name != 'AGE' &&
                        r.attribute.name != 'HEIGHT') ||
                      (r.attribute.name == 'HIJAB' && user.gender == 2)
                    )
                  })"
                  :key="index"
                  severity="info"
                  :value="c.attribute.name"
                  class="mx-1 my-1"
                >
                  <div class="flex">
                    <div v-if="c.attribute.icon != null" class="mr-2">
                      <font-awesome-icon :icon="c.attribute.icon" />
                    </div>
                    <div v-else class="mr-2">
                      <font-awesome-icon
                        icon="fa-solid fa-check"
                        v-if="c.value == true"
                      />
                      <font-awesome-icon
                        icon="fa-solid fa-xmark"
                        v-if="c.value == false"
                      />
                    </div>

                    <div>
                      <span>
                        {{
                          getCriteriaValue(c.attribute.name, c.value).label
                        }}</span
                      >
                    </div>
                  </div>
                </Tag>
              </span>
            </div>
          </div>
          <div
            class="flex mb-5"
            v-if="!isMe && matching && matching.status == 'ACCEPTED'"
          >
            <div class="flex-1 mr-3">
              <router-link
                class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                to="/chat-rooms"
              >
                <font-awesome-icon
                  icon="fa-solid fa-comment"
                  style="color: #ffffff"
                />
                Chat
              </router-link>
            </div>
            <div v-if="false" class="flex-1 mr-3 text-center">
              <button
                class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                <font-awesome-icon
                  icon="fa-solid fa-video"
                  style="color: #ffffff"
                />
                Video
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--MORE-->
    <div class="w-full user-container text-left">
      <Message v-if="!user.enabled" severity="warn" :closable="false"
        >Profil en cours de validation</Message
      >
      <div class="my-10 profil-info">
        <h1 class="title">À propos de moi</h1>
        <p class="p-10" v-html="user.bio"></p>
      </div>
      <div class="my-10 profil-info">
        <h1 class="title">Ce que je recherche chez mon conjoint</h1>
        <p class="p-10" v-html="user.partner_description"></p>
      </div>
      <div class="my-10 profil-info">
        <h1 class="title">Appréciation du staff</h1>
        <p class="p-10" v-html="user.note"></p>
      </div>

      <div class="results" v-if="false">
        <div class="col">
          <div>
            <progress-bar
              size="large"
              val="60"
              text="Religion"
              text-position="top"
              bar-border-radius="10"
              bar-color="#FF6F61"
            ></progress-bar>
          </div>
          <div>
            <progress-bar
              size="large"
              val="90"
              text="Personnalité"
              text-position="top"
              bar-border-radius="10"
              bar-color="#98FB98"
            ></progress-bar>
          </div>
          <div>
            <progress-bar
              size="large"
              val="30"
              text="Valeur familiale"
              text-position="top"
              bar-border-radius="10"
              bar-color="#ADD8E6"
            ></progress-bar>
          </div>
          <div>
            <progress-bar
              size="large"
              val="70"
              text="Vie de couple"
              text-position="top"
              bar-border-radius="10"
              bar-color="#FFD700"
            ></progress-bar>
          </div>
          <div>
            <progress-bar
              size="large"
              val="20"
              text="Education"
              text-position="top"
              bar-border-radius="10"
              bar-color="#800080"
            ></progress-bar>
          </div>
        </div>
        <div class="col"></div>
      </div>
    </div>
    <Dialog :visible.sync="reject" header="Merci de justifier la raison">
      <div class="field-radiobutton mb-2">
        <RadioButton
          inputId="details1"
          name="declineCause"
          value="PICTURE_MISSING"
          v-model="declineForm.cause"
        />
        <label for="details1" class="ml-2">La photo est manquante</label>
      </div>
      <div class="field-radiobutton mb-2">
        <RadioButton
          inputId="details1"
          name="declineCause"
          value="AGE_NOT_MATCH"
          v-model="declineForm.cause"
        />
        <label for="details1" class="ml-2">L'âge ne correspond pas</label>
      </div>
      <div class="field-radiobutton mb-2">
        <RadioButton
          inputId="details1"
          name="declineCause"
          value="HEIGTH_NOT_MATCH"
          v-model="declineForm.cause"
        />
        <label for="details1" class="ml-2">La taille ne correspond pas</label>
      </div>
      <div class="field-radiobutton mb-2">
        <RadioButton
          inputId="details1"
          name="declineCause"
          value="LOCALISATION_NOT_MATCH"
          v-model="declineForm.cause"
        />
        <label for="details1" class="ml-2"
          >La distance géographique est trop importante</label
        >
      </div>
      <div class="field-radiobutton mb-2">
        <RadioButton
          inputId="details2"
          name="declineCause"
          value="PHYSICAL"
          v-model="declineForm.cause"
        />
        <label for="details2" class="ml-2">La photo ne me plait pas</label>
      </div>
      <div class="field-radiobutton mb-2">
        <RadioButton
          inputId="details3"
          name="declineCause"
          value="OTHER"
          v-model="declineForm.cause"
        />
        <label for="details3" class="ml-2">Autres</label>
      </div>
      <Textarea
        v-model="declineForm.details"
        rows="5"
        cols="30"
        placeholder="Détaillez un peu plus les raisons de votre choix..."
      />

      <div class="mt-5">
        <Button label="Valider" @click="rejectAction()"></Button>
      </div>
    </Dialog>
    <Dialog
      id="chat"
      header="Chat Abass 💍 Kaya"
      :visible.sync="display"
      :maximizable="true"
      :modal="true"
      @hide="closeChat()"
      :containerStyle="{ width: '80vw' }"
    >
      <div>
        <vue-advanced-chat
          :current-user-id="auth.id"
          :rooms="JSON.stringify(rooms)"
          :messages="JSON.stringify(this.$store.state.messages)"
          show-add-room="false"
          rooms-loaded="true"
          :messages-loaded="messagesLoaded"
          show-search="false"
          show-audio="false"
          show-files="false"
          :room-actions="JSON.stringify(roomActions)"
          @send-message="sendMessage($event.detail[0])"
          @fetch-messages="fetchMessages($event.detail[0])"
        />
      </div>
    </Dialog>
  </div>
</template>
<script>
import { register } from 'vue-advanced-chat'
register()
import { mapGetters } from 'vuex'
import Textarea from 'primevue/textarea'
import ConfirmDialog from 'primevue/confirmdialog'
import ProgressBar from 'vue-simple-progress'
import Tag from 'primevue/tag'

import Tooltip from 'primevue/tooltip'
import Inplace from 'primevue/inplace'
import Dialog from 'primevue/dialog'
import Message from 'primevue/message'
import Button from 'primevue/button'
import RadioButton from 'primevue/radiobutton'
import { invalid } from 'moment'
export default {
  name: 'UserDetail',
  components: {
    Textarea,
    ProgressBar,
    Message,
    RadioButton,
    ConfirmDialog,
    Tag,
    Dialog,
    Button,
  },
  data() {
    return {
      user: null,
      matching: null,
      interactions: null,
      avatar_link: null,
      display: false,
      reject: false,
      declineForm: { cause: null, details: null },
      currentUserId: '1234',
      roomActions: [
        { name: 'inviteUser', title: 'Invite User' },
        { name: 'removeUser', title: 'Remove User' },
        { name: 'deleteRoom', title: 'Delete Room' },
      ],
      rooms: [
        {
          roomId: '1',
          roomName: 'Kaya X Abass',
          avatar: 'https://sakinamariage.com/favicon.ico',
          lastMessage: {
            _id: '',
            content: '',
            senderId: '',
          },
          users: [
            { _id: '1234', username: 'John Doe' },
            { _id: '4321', username: 'John Snow' },
          ],
        },
      ],
      messages: [],
      messagesLoaded: true,
    }
  },
  computed: {
    ...mapGetters(['auth', 'profil', 'regions', 'constants']),
    isMe() {
      return this.auth.id == this.$route.params.id
    },
    age() {
      if (this.user != null && this.user.birth_date != null) {
        return Math.floor(
          (new Date() - new Date(this.user.birth_date).getTime()) / 3.15576e10
        )
      } else {
        return 0
      }
    },
    region() {
      if (this.user.region)
        return this.regions.filter((r) => r.id == this.user.region)[0].name
      else return null
    },
  },
  directives: {
    tooltip: Tooltip,
  },
  mounted: async function () {
    this.user = await this.$store.dispatch(
      'loadUserDetails',
      this.$route.params.id
    )

    this.interactions = await this.$store.dispatch(
      'loadUserInteraction',
      this.$route.params.id
    )

    if (this.user.avatar != null) {
      let myBlob = await this.$store.dispatch(
        'getAvatar',
        this.user.avatar
      )
      this.avatar_link = URL.createObjectURL(myBlob)
    }
  },
  methods: {
    hasSentLike(){
      return this.interactions!= null && this.interactions.filter(i => {
          return i.sender_id == this.auth.id && i.type === 'LIKE'
      })[0] != null
    },
    hasSentDislike(){
      return this.interactions!= null &&  this.interactions.filter(i => {
        return i.sender_id == this.auth.id && i.type === 'DISLIKE'
      })[0] != null
    },
    hasReceivedLike(){
      return this.interactions!= null &&  this.interactions.filter(i => {
        return i.receiver_id == this.auth.id && i.type === 'LIKE'
      })[0] != null
    },
    hasReceivedDislike(){
      return this.interactions!= null &&  this.interactions.filter(i => {
        return i.receiver_id == this.auth.id && i.type === 'DISLIKE'
      })[0] != null
    },
    getHeight: function (attr) {
      return attr.filter((a) => {
        return a.attribute.id == 24
      })[0].value
    },
    maskPhoneNumbersAndEmails(text) {
      // Regular expression pattern for matching French phone numbers
      const frenchPhoneNumberPattern = /(?:\+33|0)[1-9](?:[\s.-]?[0-9]{2}){4}/g

      // Regular expression pattern for matching email addresses
      const emailPattern = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/g

      // First, replace all French phone numbers with X's
      let maskedText = text.replace(frenchPhoneNumberPattern, (match) =>
        'X'.repeat(match.length)
      )

      // Then, replace all email addresses with X's
      maskedText = maskedText.replace(emailPattern, (match) =>
        'X'.repeat(match.length)
      )

      return maskedText
    },
    interested() {
      this.$confirm.require({
        message: `${this.user.first_name} sera notifié de votre intérêt pour son profil. S'il/elle vous like en retour vous serez mis en relation pour une conversation privée. Souhaitez vous confirmer ?`,
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: async () => {
          //callback to execute when user confirms the action
          try {
            await this.$store.dispatch('sendInteraction', {
              type: 'LIKE',
              user: this.user,
            })
            this.$toast.add({
              severity: 'success',
              closable: false,
              summary: 'Notification envoyée',
              detail: 'Votre demande a été envoyée à ' + this.user.first_name,
              life: 3000,
            })
            this.interactions = await this.$store.dispatch(
              'loadUserInteraction',
              this.$route.params.id
            )
          } catch (error) {
            this.$toast.add({
              severity: 'error',
              closable: false,
              summary: 'Vous ne pouvez plus inviter ' + this.user.first_name,
              detail: this.user.first_name + ' a déja une invitation en cours.',
              life: 3000,
            })
          }
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      })
    },
    accepted() {
      this.$confirm.require({
        message: `Souhaitez-vous accepter l'invitation de ${this.user.first_name}, souhaitez vous confirmer ?`,
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: async () => {
          //callback to execute when user confirms the action
          try {
            await this.$store.dispatch('acceptInvitation', this.user)
            this.$toast.add({
              severity: 'success',
              closable: false,
              summary: 'Invitation confirmée',
              detail: 'Votre réponse a été envoyée à ' + this.user.first_name,
              life: 3000,
            })
            this.interaction = await this.$store.dispatch(
              'loadUserInteraction',
              this.$route.params.id
            )
          } catch (error) {
            this.$toast.add({
              severity: 'error',
              closable: false,
              summary: 'Vous ne pouvez plus inviter ' + this.user.first_name,
              detail: this.user.first_name + ' a déja une invitation en cours.',
              life: 3000,
            })
          }
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      })
    },
    uploadAvatar: function () {
      // Access the selected file
      var uploadedFile = this.$refs.fileUploadInput.files[0]
     
      this.$store.dispatch('uploadAvatar', uploadedFile)
      // You can perform additional actions with the file if needed
    },
    getCriteriaValue: function (name, value) {
      let key = name.toLowerCase()
      
      if (this.constants[key]) {
        let obj = this.constants[key].filter((c) => {
          return c.value == value
        })[0]

        return obj
      } else {
        return 'NA'
      }
    },
    rejectAction: async function () {
      this.declineForm.user = this.user
      await this.$store.dispatch('sendInteraction', {
        type: 'DISLIKE',
        declineForm: this.declineForm,
        user: this.user,
      })
      this.interactions = await this.$store.dispatch(
              'loadUserInteraction',
              this.$route.params.id
            )
      this.$toast.add({
        severity: 'success',
        closable: false,
        summary: 'Notification envoyée',
        detail: "Vous n'avez pas aimé le profil de :" + this.user.first_name,
        life: 3000,
      })

      this.interaction = await this.$store.dispatch(
        'loadUserInteraction',
        this.$route.params.id
      )
      this.reject = false
    },
    closeChat: function () {
      this.$store.dispatch('unsuscribeToMessage')
      console.log('unsuscribeToMessage')
    },
    openChat: function () {
      this.display = true
      this.$store.dispatch('subscribeToMessage')
      this.messages.push({
        _id: '1',
        senderId: '1',
        content:
          "👋 Salam 3alaykoum!  Nous tenons à rappeler à tous les utilisateurs de ce chat de faire preuve de respect et de courtoisie. Nous encourageons un environnement sain et respectueux où chacun se sent à l'aise de s'exprimer et d'échanger des idées.",
        username: 'Admin',
        system: true,
        roomId: 1,
        date: new Date().toDateString(),
        timestamp: new Date().toString().substring(16, 21),
      })
      this.messagesLoaded = true
    },
    inviteUser: function () {},
    openRejectModal: function () {
      this.reject = true
    },
    acceptInvit: function () {},
    async fetchMessages({ options = {} }) {
      this.messagesLoaded = false
      await this.$store.dispatch('loadMessages', 1)
      console.log(this.$store.state.messages)
      // use timeout to imitate async server fetched data
      setTimeout(() => {
        this.messagesLoaded = true
      }, 1000)
    },

    addMessages(reset) {
      const messages = []

      for (let i = 0; i < 30; i++) {
        messages.push({
          _id: reset ? i : this.messages.length + i,
          content: `${reset ? '' : 'paginated'} message ${i + 1}`,
          senderId: '4321',
          username: 'John Doe',
          system: true,
          roomId: 1,
          date: '13 November',
          timestamp: '10:20',
        })
      }

      return messages
    },

    sendMessage(message) {
      var msg = {
        _id: this.messages.length,
        content: this.maskPhoneNumbersAndEmails(message.content),
        senderId: this.auth.id,
        seen: false,
        distributed: false,
        roomId: 1,
        new: true,
        timestamp: new Date().toString().substring(16, 21),
        date: new Date().toDateString(),
      }
      
      this.messages = [...this.messages, msg]
      this.rooms[0].lastMessage = msg
      this.$store.dispatch('sendMessage', msg)
    },

    addNewMessage() {
      setTimeout(() => {
        this.messages = [
          ...this.messages,
          {
            _id: this.messages.length,
            content: 'NEW MESSAGE',
            senderId: '1234',
            timestamp: new Date().toString().substring(16, 21),
            date: new Date().toDateString(),
          },
        ]
      }, 2000)
    },
  },
}
</script>

<style scoped>
.profil {
  background-color: #f9f9f9;
}
.profil-info {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 15px;
}
.results {
  display: flex;
}

.results .col {
  flex: 1;
}
.w-1000 {
  width: 1000px;
  margin: auto;
}

.desc {
  font-size: 1rem;
}
.first_name {
  font-family: serif;
  font-size: 2rem;
}
.title {
  font-family: serif;
  font-size: 2rem;
}
.main-photo {
  position: relative;
  top: -100px;
}

t .shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
#file-upload-input {
  display: none;
}
#upload {
  cursor: pointer;
}
.user-container {
  border: 1px solid;
  border-radius: 20px;
  border-color: #ccc;
  padding: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  max-width: 1140px;
  width: 100%;
  margin: auto;
  margin-bottom: 100px;
}
.introduction {
  width: 50%;
}
.main-photo {
  width: 10%;
}
</style>
