<template>
  <transition name="fade">
    <div class="container" v-if="currentItem">
      <div class="q">
        <h1 class="qname">{{ currentItem.title }}</h1>
        <p v-if="currentItem.description">{{ currentItem.description }}</p>
      </div>
      <div class="text-left">
        <t-alert v-if="currentItem.info" :dismissible="false" show>
          {{ currentItem.info }}
        </t-alert>
        <Message v-if="responseIsMultiple">Plusieurs réponses possible</Message>
      </div>
      <div class="r" v-if="responseIsRadio">
        <CardRadio v-for="r in currentItem.choices" :response="r" :key="r.id" />
      </div>
      <div class="r" v-if="responseIsRegion">
        <Dropdown
                    v-model="selectedRegion"
                    class="w-full"
                    :options="regions"
                    optionLabel="name"
                    optionValue="value"
                    placeholder="--"
                    :value="userResponse"
                    @change="handleDropdownChange"
                  />
       <!--<Dropdown
          v-model="region"
          :options="regions"
          :selected="userResponse"
          :value="userResponse"
          @dropdown-change="handleDropdownChange"
        />-->
      </div>
      <div class="r" v-if="responseIsMultiple">
        <checkbox
          v-for="c in currentItem.choices"
          :response="c"
          :key="c.id"
          :selected="userResponse"
        />
      </div>
      <div class="r" v-else-if="responseIsShort">
        <input
          type="text"
          name=""
          :id="currentItem.id"
          :value="userResponse"
          class="field w-1/2"
          :placeholder="currentItem.title"
          v-on:keyup="store"
          required
        />
      </div>
      <div class="r" v-else-if="responseIsRange">
        <span style="line-height: 4; margin-right: 5px; font-weight: 700"
          >Entre</span
        >
        <input
          type="number"
          name="min_value"
          :id="currentItem.id"
          value=""
          class="field w-1/5"
          placeholder="20"
          v-on:keyup="store"
          required
        />
        <span
          style="
            line-height: 4;
            margin-left: 5px;
            margin-right: 5px;
            font-weight: 700;
          "
          >Et</span
        >
        <input
          type="number"
          name="max_value"
          :id="currentItem.id"
          value=""
          class="field w-1/5"
          placeholder="max"
          v-on:keyup="store"
        />
      </div>
      <div class="r" v-else-if="responseIsText">
        <textarea
          name="bio"
          :id="currentItem.id"
          value=""
          class="field w-full"
          :placeholder="currentItem.title"
          v-on:keyup="store"
        />
      </div>
      <div class="r" v-else-if="responseIsDate">
        <InputMask
                :value="userResponse"
                v-model="userResponse"
                class="field w-1/2"
                :id="currentItem.id"
                mask="99/99/9999"
                v-on:keyup="store"
          ></InputMask>
        <!--<input
          type="text"
          name=""
          :id="currentItem.id"
          :value="userResponse"
          class="field w-1/2"
          :placeholder="currentItem.title"
          v-on:keyup="store"
        />-->
      </div>
    </div>
  </transition>
</template>

<script>
import CardRadio from "../components/CardRadio.vue";
import Checkbox from "./Checkbox.vue";
import Message from "primevue/message";
import InputMask from "primevue/inputmask";
import Dropdown from 'primevue/dropdown';
import _ from 'lodash';
export default {
  name: "QuestionResponse",
  props: {},
  components: {
    Message,
    CardRadio,
    Checkbox,
    Dropdown,
    InputMask,
  },
  data() {
    return {
      response: { question_id: "", response: {}, value: "" },
      region:null,
    };
  },
  computed: {
    currentItem() {
      return this.$store.getters.getCurrentItem;
    },
    selectedRegion:{
      get() {
        if(this.currentItem.user_response != null &&
          this.currentItem.user_response.length > 0)
          return this.currentItem.user_response[0].value;
          else
          return null;
      },
      set(newValue) {
        this.region = newValue
      }
    },

    regions() {
      return this.$store.getters.regions;
    },
    currentUserResponse() {
      return this.$store.getters.getCurrentUserResponse;
    },
    responseIsDate() {
      return this.currentItem.response_type == "DATE";
    },
    responseIsRadio() {
      return this.currentItem.response_type == "RADIO";
    },
    responseIsShort() {
      return this.currentItem.response_type == "SHORT_ANSWER";
    },
    responseIsMultiple() {
      return this.currentItem.response_type == "CHECK_BOXES";
    },
    responseIsRange() {
      return this.currentItem.response_type == "RANGE";
    },
    responseIsText() {
      return this.currentItem.response_type == "TEXT";
    },
    responseIsRegion() {
      return this.currentItem.response_type == "REGION";
    },
    userResponse() {
      if (this.responseIsShort || this.responseIsDate)
        return this.currentItem.user_response != null &&
          this.currentItem.user_response.length > 0
          ? this.currentItem.user_response[0].value
          : "";
      else if (this.responseIsMultiple) {
        console.log(
          this.currentItem.user_response.map(function (r) {
            return r.response.id;
          })
        );
        return this.currentItem.user_response.map(function (r) {
          return r.response.id;
        });
      } else if (this.responseIsRegion) {
        return this.currentItem.user_response != null &&
          this.currentItem.user_response.length > 0
          ? this.currentItem.user_response[0].value
          : 0;
      } else return "";
    },
  },
  mounted(){
this.region = null
  },
  methods: {
    store(event) {
      var response;
      var value;
      if(this.currentItem.question_id == 17){
        value = _.replace(event.target.value, /\D/g, '');
      } else
      value = event.target.value
      if (this.responseIsRange && this.currentUserResponse.length > 0) {
        response = this.currentUserResponse[0];
        if (event.target.name && event.target.name == "min_value")
          response.min_value = value || "18";
        else if (event.target.name && event.target.name == "max_value")
          response.max_value = value || "60";
        else response.value = value;
        this.$store.commit("setUserResponse", response);
      } else {
        if (event.target.name && event.target.name == "min_value")
          response = {
            question_id: this.currentItem.question_id,
            min_value: value || "18",
            max_value: null,
            value: null,
          };
        else if (event.target.name && event.target.name == "max_value")
          response = {
            question_id: this.currentItem.question_id,
            max_value: value || "60",
            min_value: null,
            value: null,
          };
        else
          response = {
            question_id: this.currentItem.question_id,
            value: value,
          };

        this.$store.commit("setUserResponse", response);
      }
    },
    handleDropdownChange(event) {
     
      //this.region =event.value
      // this.regions.filter((r)=>{return r.value == event.value})[0];
      var response = {
        question_id: this.currentItem.question_id,
        value: event.value,
      };
      this.$store.commit("setUserResponse", response);
      //this.region = null
    },
  },
};
</script>
<style scoped>
.qname {
  font-size: 3rem;
  text-align: left;
  font-family: "Serif";
}
.container {
  width: 700px;
  min-height: 550px;
  margin: auto;
  margin-top: 70px;
}
.radio {
  display: flex;
}
.radio label {
  font-weight: 700;
  margin-left: 10px;
  font-size: 1rem;
}
.r {
  padding: 20px 0px;
  margin-top: 40px;
  margin-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
}
.field {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 10px;
}
p {
  text-align: left;
  font-size: 1.2rem;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
