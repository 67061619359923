<template>
  <div class="container">
    <h1 class="title text-center">Je découvre mes matchs</h1>
    <div
      v-if="matchs.p1 != null"
      class="w-full flex-1 p-10 text-center mr-3 mb-3 match__card_1"
      style="border-radius: 20px"
    >
      <div class="mb-3">
        <Avatar
          :label="matchs.p1.match_id.first_name.substring(0, 1).toUpperCase()"
          shape="circle"
          size="large"
          style="background-color: #5e8f32; color: #ffffff"
        />
      </div>
      <div class="my-3">
        <div class="mb-2 font-bold">
          {{ matchs.p1.match_id.first_name }},
          {{ getAge(matchs.p1.match_id.profil_attribute) }}
          ans,{{ getHeight(matchs.p1.match_id.profil_attribute) }}
          cm, Paris
        </div>
        <div class="mb-3">
          <Badge
            :value="
              avg(
                percent(matchs.p1.total_criterias / 21).toFixed(0),
                percent(matchs.p2.total_criterias / 21).toFixed(0)
              ) + '%'
            "
            size="small"
            :severity="
              getSeverity(
                avg(
                  percent(matchs.p1.total_criterias / 21).toFixed(0),
                  percent(matchs.p2.total_criterias / 21).toFixed(0)
                )
              )
            "
          ></Badge>
        </div>

        <div class="mt-5">
          <router-link
            :to="{
              name: 'UserDetail',
              params: { id: matchs.p1.match_id.id },
            }"
            class="mx-auto p-button-rounded p-button text-center"
            label="Démarrer"
            style="padding: 10px 50px"
            icon="pi pi-arrow-right"
            iconPos="right"
            >Voir le profil</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Textarea from 'primevue/textarea'
import ConfirmDialog from 'primevue/confirmdialog'
import ProgressBar from 'vue-simple-progress'
import Tag from 'primevue/tag'
import Editor from 'primevue/editor'
import Tooltip from 'primevue/tooltip'
import Inplace from 'primevue/inplace'
import Dialog from 'primevue/dialog'
import Message from 'primevue/message'
import Button from 'primevue/button'
import RadioButton from 'primevue/radiobutton'
import Badge from 'primevue/badge'
import Avatar from 'primevue/avatar'
export default {
  name: 'UserMatch',
  components: {
    Avatar,
    Badge,
  },
  data() {
    return {
      matchs: [],
    }
  },
  computed: {
    ...mapGetters(['auth', 'profil', 'regions', 'constants']),
    isMe() {
      return this.auth.id == this.$route.params.id
    },
  },
  directives: {
    tooltip: Tooltip,
  },
  mounted: async function () {
    this.matchs = await this.$store.dispatch('loadMatchs', this.auth.id)
  },
  methods: {
    getCriteriaValue: function (name, value) {
      let key = name.toLowerCase()

      if (this.constants[key]) {
        let obj = this.constants[key].filter((c) => {
          return c.value == value
        })[0]

        return obj
      } else {
        return 'NA'
      }
    },
    percent: function (value) {
      if (value <= 1) {
        return value * 100
      }
    },
    getSeverity(value) {
      if (value >= 80) return 'success'
      else if (value >= 60) {
        return 'warning'
      } else return 'danger'
    },
    avg: function (v1, v2) {
      let result = (parseFloat(v1) + parseFloat(v2)) / 2

      return result
    },
    getAge: function (attr) {
      let age = attr.filter((a) => {
        return a.attribute.id == 10
      })[0]
      return age != null ? age.value : 'NA'
    },
    getHeight: function (attr) {
      return attr.filter((a) => {
        return a.attribute.id == 24
      })[0].value
    },
  },
}
</script>

<style scoped>
.profil {
  background-color: #f9f9f9;
}
.profil-info {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 15px;
}
.results {
  display: flex;
}

.results .col {
  flex: 1;
}
.w-1000 {
  width: 1000px;
  margin: auto;
}

.desc {
  font-size: 1rem;
}
.first_name {
  font-family: serif;
  font-size: 2rem;
}
.title {
  font-family: serif;
  font-size: 2rem;
}
.main-photo {
  position: relative;
  top: -100px;
}
.shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
#file-upload-input {
  display: none;
}
#upload {
  cursor: pointer;
}
.user-container {
  border: 1px solid;
  border-radius: 20px;
  border-color: #ccc;
  padding: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  max-width: 1140px;
  width: 100%;
  margin: auto;
}
.introduction {
  width: 50%;
}
.main-photo {
  width: 10%;
}
</style>
