<template>
<div>
    <QuestionResponse />
</div>
</template>
<script>

import QuestionResponse  from '../components/QuestionResponse.vue'
export default {
  name: 'Section',
  components: {
    QuestionResponse,
  },
   data(){
    return {
    }
  },
  computed: {
    
  }
}
</script>

<style scoped>


</style>