<template>
  <div v-if="profil != null" class="">
    <div v-if="profil.bio != null && profil.partner_description != null">
      <Toast position="top-center" />
      <div class="header">
        <div class="header-img"><img src="@/assets/sakina-hero-2.png" /></div>
        <div class="profil-desc">
          <div class="main-photo flex-initial">
            <img
              v-if="avatar_link == null"
              src="https://placehold.co/300x300"
              class="border-4 border-white border-solid rounded-xl"
            />
            <img
              v-else
              :src="avatar_link"
              class="border-4 border-white border-solid rounded-xl"
            />
            <div
              v-if="isMe && true"
              class="bottom-0 right-1 text-center"
            >
              <a href="#" @click="openDialog" style="font-size: 14px;">modifier</a>
            </div>
          </div>
          <div class="introduction flex-initial text-left pl-5 mt-5">
            <div class="flex" style="align-items: center">
              <span class="first_name mx-2">{{ profil.first_name }}</span>
              <div
                style="
                  margin-right: 5px;
                  width: 13px;
                  height: 13px;
                  border-radius: 50%;
                  background-color: #009900;
                "
              ></div>
              <span>Online</span>
            </div>
            <div class="my-3">
              <div class="flex">
                <div v-if="age > 0" class="mx-2">{{ age }} ans</div>
                <div v-if="this.height" class="mx-2">{{ this.height }} cm</div>
                <div v-if="region" class="mx-2">
                  <Tag :value="region">
                    <font-awesome-icon icon="fa-solid fa-location-dot" />&nbsp;
                    {{ region }}
                  </Tag>
                </div>
              </div>
              <div class="my-5">
                <span class="desc">
                  <Tag
                    v-for="(c, index) in profil.profil_attribute.filter((r) => {
                      return (
                        (r.attribute.group == 1 || r.attribute.group == 2) &&
                        r.attribute.type != 'range'
                      )
                    })"
                    :key="index"
                    severity="info"
                    :value="c.attribute.name"
                    class="mx-1 my-1"
                    rounded
                  >
                    <div class="flex">
                      <div v-if="c.attribute.icon != null" class="mr-2">
                        <font-awesome-icon :icon="c.attribute.icon" />
                      </div>
                      <div v-else class="mr-2">
                        <font-awesome-icon
                          icon="fa-solid fa-check"
                          v-if="c.value == 'true'"
                        />
                        <font-awesome-icon
                          icon="fa-solid fa-xmark"
                          v-if="c.value == 'false'"
                        />
                      </div>

                      <div>
                        <span>
                          {{
                            getCriteriaValue(c.attribute.name, c.value)?.label
                          }}</span
                        >
                      </div>
                    </div>
                  </Tag>
                </span>
              </div>
            </div>
            <div class="flex" v-if="!isMe">
              <div class="flex-1 mr-3">
                <button
                  class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  @click="openChat"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-comment"
                    style="color: #ffffff"
                  />
                  Chat
                </button>
              </div>
              <div class="flex-1 mr-3">
                <button
                  class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-video"
                    style="color: #ffffff"
                  />
                  Video
                </button>
              </div>
            </div>
          </div>
          <div class="action flex-initial w-2/5 my-7" v-if="!isMe">
            <div class="flex">
              <div class="flex-1 mr-3">
                <button
                  class="w-full bg-white text-grey font-bold py-2 px-4 rounded border border-grey"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-flag"
                    style="color: red"
                  />
                  Signaler le profil
                </button>
              </div>
              <!-- <div class="flex-1 mr-3">
                <button
                  class="w-full bg-white text-grey font-bold py-2 px-4 rounded border border-grey"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-thumbs-down"
                    style="color: #ff0000"
                  />
                  Je n'aime pas
                </button>
              </div> -->
            </div>
          </div>
          <Message v-if="!profil.enabled" severity="warn" :closable="false"
            >Profil en cours de validation</Message
          >
        </div>
      </div>

      <!--MORE-->
      <div class="text-left user-container">
        <TabView class="tabview-custom">
          <TabPanel>
            <template #header>
              <i class="pi pi-user"></i>
              <span>Profil</span>
            </template>
            <div style="width: 100%">
              <div class="my-10 profil-info">
                <div
                  v-for="groupKey in Object.keys(allCriterias)"
                  :key="groupKey"
                >
                  <h2 class="title">{{ constants['section'][groupKey] }}</h2>
                  <div
                    class="flex my-3 p-details"
                    v-for="obj in allCriterias[groupKey]"
                    :key="obj.id"
                  >
                    <label class="one">{{ obj.label }}</label>
                    <div v-if="obj.type == 'range' && obj.name == 'HEIGHT'">
                      <h3>{{ datas[obj.name.toLowerCase()][0] }} cm</h3>
                      <Slider
                        v-model="datas[obj.name.toLowerCase()][0]"
                        :step="1"
                        :min="100"
                        :max="200"
                        class="two"
                      />
                    </div>
                    <div v-if="obj.type == 'range' && obj.name == 'AGE'">
                      <h3>{{ datas[obj.name.toLowerCase()][0] }} ans</h3>
                      <Slider
                        v-model="datas[obj.name.toLowerCase()][0]"
                        :step="1"
                        :min="18"
                        :max="75"
                        class="two"
                      />
                    </div>
                    <Dropdown
                      v-if="obj.type == 'list'"
                      v-model="datas[obj.name.toLowerCase()]"
                      class="two"
                      :options="constants[obj.name.toLowerCase()]"
                      optionLabel="label"
                      placeholder="--"
                    />
                    <InputNumber
                      v-if="obj.type == 'numeric'"
                      v-model="datas[obj.name.toLowerCase()]"
                      class="ml-0 two"
                    />

                    <MultiSelect
                      v-if="obj.type == 'multilist'"
                      v-model="datas[obj.name.toLowerCase()]"
                      class="two"
                      display="chip"
                      :options="constants[obj.name.toLowerCase()]"
                      optionLabel="label"
                      placeholder=" -- "
                    />
                    <InputSwitch
                      v-model="datas[obj.name.toLowerCase()]"
                      v-if="obj.type == 'switch'"
                    />
                  </div>
                  <div class="divider my-5"></div>
                </div>
                <Button label="Mettre à jour" @click="updateProfil()"></Button>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <template #header>
              <i class="pi pi-search"></i>
              <span>Recherche</span>
            </template>
            <div style="width: 100%">
              <div class="my-10 profil-info">
                <div
                  v-for="groupKey in Object.keys(allCriterias)"
                  :key="groupKey"
                >
                  <h2 class="title">{{ constants['section'][groupKey] }}</h2>
                  <div
                    class="flex my-3 p-details"
                    v-for="obj in allCriterias[groupKey]"
                    :key="obj.id"
                  >
                    <label class="one">{{ obj.label }}</label>
                    <div v-if="obj.type == 'range' && obj.name == 'HEIGHT'">
                      <h3>{{ datas2[obj.name.toLowerCase()] }}</h3>
                      <Slider
                        :range="true"
                        v-model="datas2[obj.name.toLowerCase()]"
                        :step="1"
                        :min="100"
                        :max="200"
                        class="two"
                      />
                    </div>
                    <div v-if="obj.type == 'range' && obj.name == 'AGE'">
                      <h3>{{ datas2[obj.name.toLowerCase()] }}</h3>
                      <Slider
                        :range="true"
                        v-model="datas2[obj.name.toLowerCase()]"
                        :step="1"
                        :min="18"
                        :max="75"
                        class="two"
                      />
                    </div>

                    <InputNumber
                      v-if="obj.type == 'numeric'"
                      v-model="datas2[obj.name.toLowerCase()]"
                      class="ml-0 two"
                    />

                    <MultiSelect
                      v-if="obj.type == 'multilist' || obj.type == 'list'"
                      v-model="datas2[obj.name.toLowerCase()]"
                      class="two"
                      display="chip"
                      :options="constants[obj.name.toLowerCase()]"
                      optionLabel="label"
                      placeholder=" -- "
                    />
                    <InputSwitch
                      v-model="datas2[obj.name.toLowerCase()]"
                      v-if="obj.type == 'switch'"
                    />
                  </div>
                  <div class="divider my-5"></div>
                </div>
                <Button label="Mettre à jour" @click="updatePartner()"></Button>
              </div>
            </div>
          </TabPanel>
          <!--  <TabPanel>
          <template #header>
            <i class="pi pi-money-bill"></i>
            <span>Facturation</span>
          </template>
          <div class="invoice flex">
            <div class="plan flex-1">
              <div class="my-5 pricing-plan" v-for="p in plans" :key="p.id">
                <div class="flex">
                  <RadioButton
                    :inputId="p.id"
                    @input="updatePlan"
                    name="plan"
                    :value="p.id"
                    v-model="plan"
                    class="flex-auto w-1/4"
                  />
                  <div class="flex-auto w-2/4">
                    <div>
                      <strong>{{ p.name }}</strong>
                    </div>
                    <div>{{ p.desc }}</div>
                  </div>
                  <div class="flex-auto w-1/4" v-if="p.id == 0">
                    <div class="font-bold">{{ p.monthly_price }}€/mois</div>
                    <Tag v-if="p.save" value="Economiser 15%"></Tag>
                  </div>
                  <div class="flex-auto w-1/4" v-else>
                    <div class="font-bold">
                      {{ p.semestrial_price }}€ ({{ p.monthly_price }}€/mois)
                    </div>
                    <Tag v-if="p.save" value="Economiser 15%"></Tag>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex-1 my-5 py-10 plan-select"
            >
              <div class="w-96 mx-auto text-center">
                <div class="my-3">
                  <span class="text-lg font-bold">{{
                    plans[selectedPlan].name
                  }}</span>
                </div>
                <div class="mt-3">
                  <span class="text-xl font-extrabold">7 Jours Gratuits</span>
                </div>
                <div class="">
                  {{ plans[selectedPlan].monthly_price }} € par mois
                </div>
                <div class="" v-if="selectedPlan != 0">
                  Payable en 1 fois:
                  {{ plans[selectedPlan].semestrial_price }} €
                </div>
                <div class="w-full mx-auto my-3 text-center">
                  <a
                    :href="plans[selectedPlan].payment_link"
                    class="p-button-info p-button-rounded p-button px-7"
                  >
                    Payer {{ plans[selectedPlan].semestrial_price }} €
                  </a>
                </div>
                <div class="flex w-24 mx-auto">
                  <img
                    class="PaymentMethodFooter-image"
                    role="presentation"
                    src="https://js.stripe.com/v3/fingerprinted/img/amex-b933c9009eeaf8cfd07e789c549b8c57.svg"
                    alt="amex"
                    id="amex"
                  /><img
                    class="PaymentMethodFooter-image"
                    role="presentation"
                    src="https://js.stripe.com/v3/fingerprinted/img/mastercard-86e9a2b929496a34918767093c470935.svg"
                    alt="mastercard"
                    id="mastercard"
                  /><img
                    class="PaymentMethodFooter-image"
                    role="presentation"
                    src="https://js.stripe.com/v3/fingerprinted/img/visa-fb36094822f73d7bc581f6c0bad1c201.svg"
                    alt="visa"
                    id="visa"
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel> -->
          <TabPanel>
            <template #header>
              <i class="pi pi-cog"></i>
              <span id="set">Réglage</span>
            </template>
            <div class="my-10 profil-info">
              <div class="">
                <h2 class="title">Informations personnelles</h2>
                <div class="flex my-3">
                  <label class="one">Prénom</label>
                  <InputText
                    type="text"
                    v-model="profil.first_name"
                    class="two"
                  />
                </div>

                <div class="flex my-3">
                  <label class="one">Date de naissance</label>
                  <Calendar
                    v-model="birth_date"
                    dateFormat="dd/mm/yy"
                    class="two"
                  />
                </div>
                <div class="flex my-3">
                  <label class="one">Adresse e-mail</label>
                  <InputText
                    type="text"
                    v-model="auth.email"
                    class="two"
                    :disabled="true"
                  />
                </div>
                <div class="flex my-3">
                  <label class="one w-full">Numéro de téléphone</label>
                  <InputText
                    type="text"
                    v-model="profil.phone_number"
                    class="two w-full"
                  />
                </div>
                <div class="flex my-3">
                  <label class="one">Bio</label>
                  <Editor
                    v-model="profil.bio"
                    editorStyle="height: 150px"
                    :placeholder="placeholder1"
                    class="two w-full"
                  >
                    <template #toolbar>
                      <span class="ql-formats">
                        <button
                          class="ql-bold"
                          v-tooltip.bottom="'Bold'"
                        ></button>
                        <button
                          class="ql-italic"
                          v-tooltip.bottom="'Italic'"
                        ></button>
                        <button
                          class="ql-underline"
                          v-tooltip.bottom="'Underline'"
                        ></button>
                      </span>
                    </template>
                  </Editor>
                </div>
                <div class="flex my-3">
                  <label class="one">Ma recherche</label>
                  <Editor
                    v-model="profil.partner_description"
                    editorStyle="height: 150px"
                    :placeholder="placeholder2"
                    class="two w-full"
                  >
                    <template #toolbar>
                      <span class="ql-formats">
                        <button
                          class="ql-bold"
                          v-tooltip.bottom="'Bold'"
                        ></button>
                        <button
                          class="ql-italic"
                          v-tooltip.bottom="'Italic'"
                        ></button>
                        <button
                          class="ql-underline"
                          v-tooltip.bottom="'Underline'"
                        ></button>
                      </span>
                    </template>
                  </Editor>
                </div>
                <div class="w-full text-right">
                  <Button
                    label="Enregistrer"
                    @click="updateBio()"
                    class="text-right"
                  ></Button>
                </div>
              </div>
              <div class="w-full">
                <Divider type="dashed"></Divider>
              </div>
              <h2 class="title">Compte</h2>
              <div class="my-5 pricing-plan">
                <div class="flex flex-col">
                  <div class="flex-auto w-full">
                    <div><strong>Mot de passe</strong></div>
                    <div>
                      Modifiez votre mot de passe régulierement pour plus de
                      confidentialité.
                    </div>
                  </div>
                  <div class="flex-auto w-full">
                    <Button label="Mot de passe"></Button>
                  </div>
                </div>
              </div>
              <div class="my-5 pricing-plan">
                <div class="flex">
                  <div class="flex-auto w-3/4">
                    <div><strong>Visibilité du profil</strong></div>
                    <div>
                      Mettez votre profil completement hors ligne pendant que
                      vous faites un pause.
                    </div>
                  </div>
                  <div class="flex-auto w-1/4 px-6">
                    <InputSwitch v-model="profil.visibility" />
                  </div>
                </div>
              </div>
              <div class="my-5 pricing-plan">
                <div class="flex flex-col">
                  <div class="flex-auto w-full">
                    <div><strong>Désactiver / Supprimer le compte</strong></div>
                    <div>
                      Mettez votre profil completement hors ligne pendant que
                      vous faites un pause ou quittez Sakina.
                    </div>
                  </div>
                  <div class="flex-auto w-full">
                    <Button label="Supprimer"></Button>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
    <div v-else class="mt-10">
      <h1 class="title text-center">Je complète mon profil</h1>
      <div
        style="
          font-size: 1rem;
          max-width: 900px;
          width: 100%;
          margin: 30px auto auto;
          background: rgb(252, 231, 243);
          padding: 30px;
          border-radius: 10px;
        "
        class="text-center"
      >
        <strong>{{ profil.first_name }} !</strong> Donnez-vous une chance de
        vous présenter sous votre meilleur jour en complétant votre bio. Plus
        vous partagez d'informations sur <strong>vos centres d'intérêt</strong>,
        <strong>votre personnalité</strong> et ce que vous recherchez, plus vous
        augmentez vos chances de trouver une connexion authentique. Prenez le
        temps de <strong>détailler votre profil</strong> afin de permettre aux
        autres membres de mieux vous connaître et de susciter leur intérêt.
      </div>

      <div
        class="w-full user-container text-left"
        style="border: none; box-shadow: none"
      >
        <div class="my-10">
          <h2 class="title">À propos de moi</h2>
          <Editor
            v-model="bio"
            editorStyle="height: 150px"
            :placeholder="placeholder1"
          >
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                <button
                  class="ql-underline"
                  v-tooltip.bottom="'Underline'"
                ></button>
              </span>
            </template>
          </Editor>
        </div>

        <div class="my-10">
          <h2 class="title">Ce que je recherche chez mon conjoint</h2>
          <Editor
            v-model="partner_description"
            editorStyle="height: 150px"
            :placeholder="placeholder2"
          >
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                <button
                  class="ql-underline"
                  v-tooltip.bottom="'Underline'"
                ></button>
              </span>
            </template>
          </Editor>
        </div>
        <Button label="Envoyer" @click="updateBio()"></Button>
      </div>
    </div>
    <Dialog :visible.sync="displayAvatar" header="Charger photo" :style="{ width: '100vw !important', height:'100%', background:'#fff' }" class="p-dialog-maximized">
    <AvatarUpload @close-dialog="closeDialog"/>
    </Dialog>
  </div>
</template>
<script>
import { Store, mapGetters } from 'vuex'
import ProgressBar from 'vue-simple-progress'
import Divider from 'primevue/divider'
import AvatarUpload from '@/components/AvatarUpload.vue'
import Button from 'primevue/button'
import Tag from 'primevue/tag'
import Dialog from 'primevue/dialog';
import Tooltip from 'primevue/tooltip'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Calendar from 'primevue/calendar'
import InputSwitch from 'primevue/inputswitch'
import Dropdown from 'primevue/dropdown'
import Slider from 'primevue/slider'
import Editor from 'primevue/editor'
import MultiSelect from 'primevue/multiselect'
import Toast from 'primevue/toast'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Message from 'primevue/message'
import Card from 'primevue/card'
import moment from 'moment'
import Vue from 'vue'
export default {
  name: 'UserEdit',
  components: {
    MultiSelect,
    InputNumber,
    AvatarUpload,
    Button,
    Tag,
    Dialog,
    Editor,
    TabView,
    TabPanel,
    InputText,
    Calendar,
    Message,
    Divider,
    InputSwitch,
    Dropdown,
    Slider,
    Toast,
  },
  data() {
    return {
      displayAvatar: false,
      user: null,
      placeholder1:
        "Ex: Je suis une personne chaleureuse et bienveillante, passionnée par la découverte du monde et des autres cultures. Ma curiosité m'a amené à voyager dans de nombreux pays, où j'ai développé une ouverture d'esprit et une tolérance envers les différences.",
      placeholder2:
        "Ex: Je suis à la recherche d'une personne authentique, attentionnée et ouverte d'esprit, qui partage ma vision de la vie et de la relation. Je valorise la sincérité, la complicité et la communication dans un couple. Je recherche quelqu'un qui saura m'inspirer, me soutenir et grandir avec moi dans la foi et la confiance en Allah.",
      selectedPlan: 0,
      bio: null,
      partner_description: null,
      plans: [
        {
          id: 0,
          name: 'Sans engagement',
          monthly_price: '19,99',
          semestrial_price: '119,94',
          desc: "Testez l'expérience de rencontre sans engagement.",
          payment_link: 'https://buy.stripe.com/test_28oeW72G1f9Gekg000',
        },
        {
          id: 1,
          name: '3 mois',
          monthly_price: '16,99',
          semestrial_price: '101,94',
          desc: 'Offre idéale pour ceux cherchant une expérience prolongée.',
          save: '15',
          payment_link: '',
        },
        {
          id: 2,
          name: '6 mois',
          monthly_price: '12,99',
          semestrial_price: '77,94',
          desc: 'La formule ultime pour des rencontres sérieuses.',
          save: '24',
          payment_link: '',
        },
      ],
      avatar_link: null,
      display: false,
      location: null,
      plan: 1,
      height: 140,
      allCriterias: [],
      datas: {},
      datas2: {},
      profil: null,
      birth_date: null,
    }
  },
  computed: {
    ...mapGetters(['auth', 'regions', 'constants', 'criterias']),
    isMe() {
      return true
    },
    age() {
      if (this.profil.birth_date != null) {
        return Math.floor(
          (new Date() - new Date(this.profil.birth_date).getTime()) / 3.15576e10
        )
      } else {
        return 0
      }
    },

    region() {
      if (this.profil.region)
        return this.regions.filter((r) => r.id == this.profil.region)[0].name
      else return null
    },
  },
  directives: {
    tooltip: Tooltip,
  },
  async mounted() {
    await this.$store.dispatch('loadCriterias')
    this.profil = await this.$store.dispatch('loadProfil', this.auth.id)
    this.bio = this.profil.bio
    this.partner_description = this.profil.partner_description
    this.allCriterias = this.criterias.reduce((acc, obj) => {
      const groupKey = obj.group

      acc[groupKey] = acc[groupKey] || []
      acc[groupKey].push(obj)
      return acc
    }, {})

    this.height = this.profil.heigth
    if (this.profil.avatar != null) {
      let myBlob = await this.$store.dispatch(
        'getAvatar',
        this.profil.avatar
      )

      //const  blobUrl=  URL.createObjectURL(myBlob);
      this.avatar_link = URL.createObjectURL(myBlob)
    }

    for (let a in this.criterias) {
      let attribute = this.criterias[a]
      let pa = this.profil.profil_attribute.filter((p) => {
        return p.attribute.id == attribute.id
      })
      let key = attribute.name.toLowerCase()

      if (pa.length > 0) {
        if (attribute.type == 'list') {
          let obj = this.constants[key].filter((c) => {
            return c.value == pa[0].value
          })[0]

          //this.datas[key] = obj;
          Vue.set(this.datas, key, obj)
        }
        if (attribute.type == 'multilist') {
          Vue.set(this.datas, key, [])
          for (let i in pa) {
            let obj = this.constants[key].filter((c) => {
              return c.value == pa[i].value
            })[0]
            this.datas[key].push(obj)
          }
        }
        if (attribute.type == 'range') {
          Vue.set(this.datas, key, [pa[0].min_value, pa[0].max_value])
        }
        if (attribute.type == 'switch') {
          let obj = this.constants[key].filter((c) => {
            return c.value == pa[0].value
          })[0]

          //this.datas[key] = obj.value == 272;
          Vue.set(this.datas, key, obj.value == 272)
        }
      } else {
        if (attribute.type == 'range') {
          Vue.set(this.datas, key, [0, 0])
        }
        if (attribute.type == 'switch') {
          Vue.set(this.datas, key, false)
        }
      }
    }
    //
    for (let a in this.criterias) {
      let attribute = this.criterias[a]
      let pa = this.profil.partner_attribute.filter((p) => {
        return p.attribute.id == attribute.id
      })

      let key = attribute.name.toLowerCase()
      if (pa.length > 0) {
        if (attribute.type == 'multilist' || attribute.type == 'list') {
          Vue.set(this.datas2, key, [])
          for (let i in pa) {
            let obj = this.constants[key].filter((c) => {
              return c.value == pa[i].value
            })[0]
            this.datas2[key].push(obj)
          }
        }
        if (attribute.type == 'range') {
          Vue.set(this.datas2, key, [pa[0].min_value, pa[0].max_value])
        }
        if (attribute.type == 'switch') {
          let obj = this.constants[key].filter((c) => {
            return c.value == pa[0].value
          })[0]

          //this.datas[key] = obj.value == 272;
          Vue.set(this.datas2, key, obj.value == 272)
        }
      } else {
        if (attribute.type == 'range') {
          Vue.set(this.datas2, key, [0, 0])
        }
        if (attribute.type == 'switch') {
          Vue.set(this.datas2, key, false)
        }
      }
    }

    //
    this.birth_date = moment(this.profil.birth_date).format('DD/MM/YYYY')
  },
  methods: {
    async closeDialog() {
      this.displayAvatar = false; // This will close the dialog
      this.profil = await this.$store.dispatch('loadProfil', this.auth.id)
      if (this.profil.avatar != null) {
      let myBlob = await this.$store.dispatch(
        'getAvatar',
        this.profil.avatar
      )

      console.log(this.avatar_link)
      if(this.avatar_link != null)
      URL.revokeObjectURL(this.avatar_link)
      console.log("new avatar")
      //const  blobUrl=  URL.createObjectURL(myBlob);
      this.avatar_link = URL.createObjectURL(myBlob)
      console.log(this.avatar_link)
    }
    },
    openDialog(){
      this.displayAvatar = true
      console.log(this.displayAvatar)
    },
    updatePlan: function (event) {
      this.selectedPlan = event
    },
    async updateBio() {
      await this.$store.dispatch('updateBio', {
        bio: this.bio,
        partner_description: this.partner_description,
      })
      this.$toast.add({
        severity: 'success',
        closable: false,
        summary: 'Sauvegarde réussie',
        detail: 'Profil mis à jour',
        life: 3000,
      })
      this.profil = await this.$store.dispatch('loadProfil', this.auth.id)
    },
    uploadAvatar: function () {
      // Access the selected file
      var uploadedFile = this.$refs.fileUploadInput.files[0]

      this.$store.dispatch('uploadAvatar', uploadedFile)
      // You can perform additional actions with the file if needed
    },
    getCriteriaValue: function (name, value) {
      let key = name.toLowerCase()

      if (this.constants[key]) {
        let obj = this.constants[key].filter((c) => {
          return c.value == value
        })[0]

        return obj
      } else {
        return 'NA'
      }
    },
    updateProfil: async function () {
      //delete
      await this.$store.dispatch('updateProfile', this.datas)
      this.$toast.add({
        severity: 'success',
        closable: false,
        summary: 'Sauvegarde réussie',
        detail: 'Profil mis à jour',
        life: 3000,
      })
    },
    updatePartner: async function () {
      await this.$store.dispatch('updatePartnerCriteria', this.datas2)
      this.$toast.add({
        severity: 'success',
        closable: false,
        summary: 'Sauvegarde réussie',
        detail: 'Profil mis à jour',
        life: 3000,
      })
    },
  },
}
</script>

<style scoped>
.profil {
  background-color: #f9f9f9;
}

.p-slider-horizontal,
.p-inputtext {
  width: 38rem;
}
.PaymentMethodFooter-image {
  height: 16px;
  margin: 4px;
  width: 24px;
}
.plan {
  width: 100%;
}
.plan-select {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 100%;
  border-radius: 10px;
}
.profil-info {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 15px;
}
.results {
  display: flex;
}

.results .col {
  flex: 1;
}
.w-1000 {
  width: 1000px;
  margin: auto;
}

.desc {
  font-size: 1rem;
}
.first_name {
  font-family: serif;
  font-size: 2rem;
}
.title {
  font-family: serif;
  font-size: 2rem;
}

.user-container {
  border: 1px solid;
  border-radius: 20px;
  border-color: #ccc;
  padding: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  max-width: 1140px;
  width: 100%;
  margin: auto;
  margin-bottom: 100px;
}
.main-photo {
  position: relative;
  top: -100px;
  width: 10%;
}
.shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.one {
  flex-basis: 33%;
}
.two {
  flex-basis: 66%;
}
#file-upload-input {
  display: none;
}
#upload {
  cursor: pointer;
}
label.one {
  font-weight: 700;
}
.tabview-custom {
  i,
  span {
    vertical-align: middle;
  }

  span {
    margin: 0 0.5rem;
  }
}
.pricing-plan {
  padding: 15px 13px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.divider {
  border-bottom: 1px solid #eee;
}

h1.title {
  font-size: 40px;
}
</style>
