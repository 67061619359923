var render = function render(){var _vm=this,_c=_vm._self._c;return (true)?_c('div',{},[_c('h1',{staticClass:"title mx-auto"},[_vm._v("Félicitation vous avez 1 match 🫶!")]),_c('div',{staticClass:"container mx-auto mb-10"},[_c('div',{staticClass:"match__card"},[_vm._m(0),_c('div',{staticClass:"details item"},[_c('div',[_vm._m(1),_c('div',[_vm._v("33 ans, Paris")]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',[_c('AvatarGroup',{staticClass:"mb-3"},[_c('Avatar',{staticStyle:{"background-color":"#9c27b0","color":"#ffffff"},attrs:{"label":"K","shape":"circle","size":"large"}}),_c('Avatar',{staticStyle:{"background-color":"#b02740","color":"#ffffff"},attrs:{"label":"A","shape":"circle","size":"large"}})],1)],1),_c('div',{staticClass:"center"},[_c('div',[_c('Button',{staticClass:"w-full p-button-rounded",attrs:{"label":"Découvrir"}})],1)])])])]),_c('div',{staticClass:"match__card back rotate-5"}),_c('div',{staticClass:"match__card back rotate-7"})])]):_c('div',[_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"photo item"},[_c('img',{attrs:{"src":require("@/assets/DISCOVER.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"text-2xl"},[_vm._v("Rania")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"label"},[_vm._v("Ma taille:")]),_vm._v(" 165 cm")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"label"},[_vm._v("Mon niveau d'étude :")]),_vm._v(" BEP")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"label"},[_vm._v("Activité : ")]),_vm._v(" Vendeuse")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"badges"},[_c('div',{staticClass:"chip"},[_c('div',{staticClass:"chip__content"},[_vm._v("Sans enfant")])]),_c('div',{staticClass:"chip"},[_c('div',{staticClass:"chip__content"},[_vm._v("Jamais mariée")])]),_c('div',{staticClass:"chip"},[_c('div',{staticClass:"chip__content"},[_vm._v("Ne fume pas")])]),_c('div',{staticClass:"chip"},[_c('div',{staticClass:"chip__content"},[_vm._v("Ne bois pas")])]),_c('div',{staticClass:"chip"},[_c('div',{staticClass:"chip__content"},[_vm._v("Mange Halal")])]),_c('div',{staticClass:"chip"},[_c('div',{staticClass:"chip__content"},[_vm._v("Fait la prière")])]),_c('div',{staticClass:"chip"},[_c('div',{staticClass:"chip__content"},[_vm._v("Porte le hijab")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-800 mx-auto flex"},[_c('div',{staticClass:"item"},[_c('h1',{staticClass:"title mx-auto"},[_vm._v("0 match !")]),_c('p',{staticClass:"text-left mr-5"},[_vm._v(" Merci de patienter, nous recherchons ton ame-soeur ! Des que notre algorithme aura trouvé des correspondances, elles apparaitront ici. En attendant tu peux compléterer le questionnaire afin d'optimiser ta recherche. ")])]),_c('div',{staticClass:"item"},[_c('img',{staticClass:"w-96 mx-auto center",attrs:{"src":require("@/assets/matching-in-progress.png")}})])])
}]

export { render, staticRenderFns }