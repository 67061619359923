<template>
<transition name="fade" mode="out-in">
  <component :is="layout">
    <slot />
  </component>
</transition>
</template>

<script>
const defaultLayout = 'AppLayoutDefault'
export default {
  name: "AppLayout",
  computed: {
    layout() {
      const layout = this.$route.meta.layout || defaultLayout
      return () => import(`@/layouts/${layout}.vue`)
    }
  }
}
</script>

<style scoped>
/*.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}*/

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>