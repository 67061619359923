<template>
  <div class="container invitation">
    <h1 class="title text-center">Mes Interactions</h1>
    <TabView class="tabview-custom">
      <TabPanel header="Vous a liké(e)">
        <div
          v-if="received != null && received.length > 0"
          class="flex sm:flex-col lg:flex-row"
        >
          <div
            v-for="invitation in received"
            :key="invitation.id"
            class="w-full flex-1 p-5 text-center mr-3 mb-3 match__card_1"
            style="border-radius: 20px"
          >
            <div class="mb-3">
              <div class="flex">
                <div>
                  <Avatar
                    :label="
                      invitation.sender_id.first_name
                        .substring(0, 1)
                        .toUpperCase()
                    "
                    shape="circle"
                    size="large"
                    style="background-color: #5e8f32; color: #ffffff"
                  />
                </div>
                <div class="ml-2 text-left">
                  <div class="mb-2" style="font-size: 0.9rem">
                    {{ invitation.sender_id.first_name }},
                    {{ getAge(invitation.sender_id.profil_attribute) }}
                    ans,{{
                      getHeight(invitation.sender_id.profil_attribute)
                    }}
                    cm,
                    {{
                      getCriteriaValue(
                        'location',
                        getRegion(invitation.sender_id.profil_attribute)
                      ).label
                    }}
                  </div>
                  <div style="font-size: 0.9rem">
                    <font-awesome-icon icon="fa-solid fa-clock" />
                    {{ format(invitation.created_at) }}
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <router-link
                  :to="{
                    name: 'UserDetail',
                    params: { id: invitation.sender_id.id },
                  }"
                  class="mx-auto p-button-sm p-button-rounded p-button text-center"
                  label="Démarrer"
                  style="padding: 10px 50px"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  >Voir le profil</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div v-else>Vous n'avez liké aucun profil</div>
      </TabPanel>
      <TabPanel header="Liké(e)">
        <div v-if="sent != null && sent.length > 0" class="flex sm:flex-col lg:flex-row">
          <div
            v-for="invitation in sent"
            :key="invitation.id"
            class="w-full flex-1 p-5 text-center mr-3 mb-3 match__card_1"
            style="border-radius: 20px"
          >
            <div class="mb-3">
              <div class="flex">
                <div>
                  <Avatar
                    :label="
                      invitation.receiver_id.first_name
                        .substring(0, 1)
                        .toUpperCase()
                    "
                    shape="circle"
                    size="large"
                    style="background-color: #5e8f32; color: #ffffff"
                  />
                </div>
                <div class="ml-2 text-left">
                  <div class="mb-2" style="font-size: 0.9rem">
                    {{ invitation.receiver_id.first_name }},
                    {{ getAge(invitation.receiver_id.profil_attribute) }}
                    ans,{{
                      getHeight(invitation.receiver_id.profil_attribute)
                    }}
                    cm,
                    {{
                      getCriteriaValue(
                        'location',
                        getRegion(invitation.receiver_id.profil_attribute)
                      ).label
                    }}
                  </div>
                  <div style="font-size: 0.9rem">
                    <font-awesome-icon icon="fa-solid fa-clock" />
                    {{ format(invitation.created_at) }}
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <router-link
                  :to="{
                    name: 'UserDetail',
                    params: { id: invitation.receiver_id.id },
                  }"
                  class="mx-auto p-button-sm p-button-rounded p-button text-center"
                  label="Démarrer"
                  style="padding: 10px 50px"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  >Voir le profil</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div v-else>Aucun profil liké</div>
      </TabPanel>
      <TabPanel header="Refusé(e)">
        <div
          v-if="rejected != null && rejected.length > 0"
          class="flex sm:flex-col lg:flex-row"
        >
          <div
            v-for="invitation in rejected"
            :key="invitation.id"
            class="w-full p-5 text-center mr-3 mb-3 match__card_1"
            style="border-radius: 20px"
          >
            <div class="mb-3">
              <div class="flex">
                <div>
                  <Avatar
                    :label="
                      invitation.receiver_id.first_name
                        .substring(0, 1)
                        .toUpperCase()
                    "
                    shape="circle"
                    size="large"
                    style="background-color: #5e8f32; color: #ffffff"
                  />
                </div>
                <div class="ml-2 text-left">
                  <div class="mb-2" style="font-size: 0.9rem">
                    {{ invitation.receiver_id.first_name }},
                    {{ getAge(invitation.receiver_id.profil_attribute) }}
                    ans,{{
                      getHeight(invitation.receiver_id.profil_attribute)
                    }}
                    cm,
                    {{
                      getCriteriaValue(
                        'location',
                        getRegion(invitation.receiver_id.profil_attribute)
                      ).label
                    }}
                  </div>
                  <div style="font-size: 0.9rem">
                    <font-awesome-icon icon="fa-solid fa-clock" />
                    {{ format(invitation.created_at) }}
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <router-link
                  :to="{
                    name: 'UserDetail',
                    params: { id: invitation.receiver_id.id },
                  }"
                  class="mx-auto p-button-sm p-button-rounded p-button text-center"
                  label="Démarrer"
                  style="padding: 10px 50px"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  >Voir le profil</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div v-else>Aucun profil refusé</div>
      </TabPanel>
    </TabView>
  </div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import Tooltip from 'primevue/tooltip'
import Badge from 'primevue/badge'
import Avatar from 'primevue/avatar'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
export default {
  name: 'UserMatch',
  components: {
    Avatar,
    TabPanel,
    TabView,
  },
  data() {
    return {
      received: null,
      sent: null,
      rejected: null,
    }
  },
  computed: {
    ...mapGetters(['auth', 'profil', 'regions', 'constants']),
    isMe() {
      return this.auth.id == this.$route.params.id
    },
  },
  directives: {
    tooltip: Tooltip,
  },
  mounted: async function () {
    this.received = await this.$store.dispatch(
      'loadReceivedLikes',
      this.auth.id
    )
    this.sent = await this.$store.dispatch('loadSentLikes', this.auth.id)
    this.rejected = await this.$store.dispatch('loadDislikes', this.auth.id)
  },
  methods: {
    handleSeverity: function (status) {
      if (status == 'ACCEPTED') return 'success'
      else if (status == 'PENDING') return 'warning'
      else return 'danger'
    },
    handleLabel: function (status) {
      if (status == 'ACCEPTED') return 'Acceptée'
      else if (status == 'PENDING') return 'En attente'
      else return 'Refusée'
    },
    format(value) {
      return moment(value).format('DD/MM/YYYY HH:mm')
    },
    getCriteriaValue: function (name, value) {
      let key = name.toLowerCase()

      if (this.constants[key]) {
        let obj = this.constants[key].filter((c) => {
          return c.value == value
        })[0]

        return obj
      } else {
        return 'NA'
      }
    },
    percent: function (value) {
      if (value <= 1) {
        return value * 100
      }
    },
    getSeverity(value) {
      if (value >= 80) return 'success'
      else if (value >= 60) {
        return 'warning'
      } else return 'danger'
    },
    avg: function (v1, v2) {
      let result = (parseFloat(v1) + parseFloat(v2)) / 2

      return result
    },
    getAge: function (attr) {
      let age = attr.filter((a) => {
        return a.attribute.id == 10
      })[0]
      return age != null ? age.value : 'NA'
    },
    getRegion: function (attr) {
      let region = attr.filter((a) => {
        return a.attribute.id == 9
      })[0]
      return region != null ? region.value : 'NA'
    },
    getHeight: function (attr) {
      return attr.filter((a) => {
        return a.attribute.id == 24
      })[0].value
    },
  },
}
</script>

<style scoped>
.profil {
  background-color: #f9f9f9;
}
.profil-info {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 15px;
}
.results {
  display: flex;
}

.results .col {
  flex: 1;
}
.invitation {
  width: 100%;
  max-width: 1068px;
  margin: auto;
}
.w-1000 {
  width: 1000px;
  margin: auto;
}

.desc {
  font-size: 1rem;
}
.first_name {
  font-family: serif;
  font-size: 2rem;
}
.title {
  font-family: serif;
  font-size: 2rem;
}
.main-photo {
  position: relative;
  top: -100px;
}
.shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
#file-upload-input {
  display: none;
}
#upload {
  cursor: pointer;
}
.user-container {
  border: 1px solid;
  border-radius: 20px;
  border-color: #ccc;
  padding: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  max-width: 1140px;
  width: 100%;
  margin: auto;
}
.introduction {
  width: 50%;
}
.main-photo {
  width: 10%;
}
</style>
